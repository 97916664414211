import { Fragment } from "react";
import AuthorRef from "../single-item/author-ref";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { TableCell, TableRow } from "../ui/table";
import { ScrollArea } from "../ui/scroll-area";

export default function ExpandableRowsComponent({
  data,
  colSpan,
}: {
  data: any;
  colSpan: number;
}) {
  const isSinglePerson =
    data.person && (!data.people || data.people.length === 0);
  const isMultiplePeople = data.people?.length > 0 && !data.person;
  const isSingleAndMultiple = data.people?.length > 0 && data.person;
  const hasAnyData = (data.people && data.people.length > 0) || !!data.person;
  return (
    <TableRow className="p-4 hover:!bg-transparent">
      <TableCell colSpan={colSpan}>
        <div
          className={`${
            isSingleAndMultiple ? "grid grid-cols-2" : "flex flex-col"
          } py-4 rounded`}
        >
          {hasAnyData ? (
            isSinglePerson ? (
              <Fragment>
                <h3 className="text-[var(--text-color)] font-medium pb-3">
                  Author
                </h3>
                <AuthorRef person={data.person} />
              </Fragment>
            ) : isMultiplePeople ? (
              <Fragment>
                <h3 className="text-[var(--text-color)] font-medium pb-3">
                  Author List
                </h3>
                <ScrollArea className="h-[360px]">
                  <div className="flex flex-col">
                    {data.people.map((prsn: any, i: number) => (
                      <AuthorRef person={prsn} key={`list:${i}`} />
                    ))}
                  </div>
                </ScrollArea>
              </Fragment>
            ) : (
              <Fragment>
                <div>
                  <h3 className="text-[var(--text-color)] font-medium pb-3">
                    Owner
                  </h3>
                  <AuthorRef person={data.person} />
                </div>
                <div>
                  <h3 className="text-[var(--text-color)] font-medium pb-3">
                    People
                  </h3>
                  <ScrollArea className="max-h-[360px]">
                    <div className="flex flex-col">
                      {data.people.map((prsn: any, i: number) => (
                        <AuthorRef person={prsn} key={`list:${i}`} />
                      ))}
                    </div>
                  </ScrollArea>
                </div>
              </Fragment>
            )
          ) : (
            <div className="self-center flex flex-col items-center gap-4 text-[var(--text-color)] opacity-80">
              <FontAwesomeIcon icon={faEyeSlash} size="2x" />
              <p className="text-sm">You have not gathered any data yet</p>
            </div>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
