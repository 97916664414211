import { ICON_TYPES } from "@/const/search";
import useMap from "@/hooks/useMap";
import { Disaster } from "@/lib/add-location/disasters";

interface Props extends Disaster {
  onSelect: () => void;
}

export default function DisasterRef({
  title,
  description,
  timestamp,
  disaster_type,
  location,
  onSelect,
}: Props) {
  const { map } = useMap();

  function onZoom() {
    if (!map) return;
    map.panTo(location);
    map.setZoom(13);
    onSelect();
  }

  return (
    <article
      role="button"
      onClick={onZoom}
      className="p-4 bg-body rounded w-72 flex items-center gap-4"
    >
      <img
        className="shrink-0 h-8 w-8 object-contain"
        src={ICON_TYPES[disaster_type]}
        alt={disaster_type}
      />
      <div>
        <p className="text-pink dark:text-blue text-sm">
          {new Intl.DateTimeFormat("en-US", {
            dateStyle: "medium",
            timeStyle: "short",
            timeZone: "UTC",
          }).format(new Date(timestamp).getTime())}{" "}
          UTC
        </p>
        <h4 className="text-lg font-medium text-font line-clamp-2">{title}</h4>
        <p className="line-clamp-1 text-sm text-font opacity-75">
          {description}
        </p>
      </div>
    </article>
  );
}
