import axios from "axios";
import useSWR from "swr";

type TrendingRecord = {
  name: string;
  url: string;
  count: string;
};

type Trending = {
  google: TrendingRecord[];
  tiktok: TrendingRecord[];
  twitter: TrendingRecord[];
};

async function getTrending(): Promise<Trending> {
  const { data } = await axios.get("/getTrending/");
  return data;
}

export default function useTrending() {
  return useSWR(["trending"], () => getTrending());
}
