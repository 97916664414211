import {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Map from "./Map";
import {
  MarkerClustererF,
  DrawingManager,
  DirectionsRenderer,
  PolygonF,
  InfoBox,
  Polyline,
  InfoWindowF,
} from "@react-google-maps/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlaceOfWorship,
  faTruckMedical,
  faTowerBroadcast,
  faTruck,
  faBolt,
  faDroplet,
  faPersonMilitaryRifle,
  faCar,
  faTriangleExclamation,
  faShip,
  faPlane,
  faTrain,
  faUndo,
  faX,
  faSun,
  faHandPointDown,
  faLocationDot,
  faEye,
  faEyeSlash,
  faNewspaper,
  faHouseChimneyUser,
  faSatellite,
  faPlaneArrival,
  faClock,
  faUsersViewfinder,
  faFutbol,
  faStore,
  faHouseFloodWater,
  faMagnifyingGlassChart,
  faRadio,
  faLink,
  faGoogle,
  faFire,
  faAnchor
} from "@fortawesome/free-solid-svg-icons";

import { useAuth0 } from "@auth0/auth0-react";

import CustomMarker from "./CustomMarker";
import DrawerItem from "../search/drawer-item";
import {
  DIRECTIONS_MODULES,
  POPUP_MODULES,
  TRANSPORT_TYPES,
  clusterIcons,
} from "../../const/search";
import {
  getCenterFromBounds,
  getDrawingManagerOptions,
  postSearch,
} from "../../utils/search/lib";
import toast, { LoaderIcon } from "react-hot-toast";
import Toast from "../toast";
import { POLYGON_MODULES } from "../../const/general";
import Button from "../Button";
import { HexColorPicker } from "react-colorful";
// import WeatherPopup from "../search/weather-popup";
import { TutorialContext } from "../../providers/tutorial";
import StepPopup from "../tutorial/step";
import useAnalysis from "../../hooks/search/useAnalysis";
import StatsList from "../stats/list";
import ActiveRecordProvider from "../../providers/stats/ActiveRecordProvider";
import NewsPopup from "../search/news-popup";
import useTransportRoute from "../../hooks/search/useTransportRoute";
import useMap from "../../hooks/useMap";
import useFilters from "@/providers/search/filters";
import Drawer from "../search/drawer";
import renderDirections from "@/utils/render-directions";
import { cn } from "@/utils/cn";

import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";
import Airport from "../search/airport";
import Stadium from "../search/stadium";
import Ports from "../search/ports";
import Store from "../search/store";
import WeatherPopup from "../search/weather-popup";
import Radio from "../search/radio";
import groupMarkers from "@/utils/search/group-markers";
import Newspapers from "../search/newspapers";
import { useParams } from "react-router-dom";
import useArea from "@/lib/search/area";
import NoflyIcon from "../../const/search/imgs/NoflyIcon";
const addressLookupModules = ["ai_satellite", "trestle", "stores", "timelapse", "google_places"]

const google = (window.google = window.google ? window.google : {});

export default function MapContainer({
  loadingRequests,
  changeLoadingRequest,
  markers,
  changePlace,
  news,
  setWeather,
  showOnly,
  // bounds,
  setNews,
  setMarkers,
  ...props
}) {
  const { workspace_id } = useParams();
  const { data: area } = useArea(workspace_id);
  const { keyword, startDate, endDate } = useFilters();
  const [areObjectsHidden, setAreObjectsHidden] = useState(false);
  const { step, isFinished } = useContext(TutorialContext);
  const [moduleTypes, setModuleTypes] = useState([]);
  const [focusedItem, setFocusedItem] = useState(null);
  const [directions, setDirections] = useState(null);
  const [clusterItems, setClusterItems] = useState([]);
  const { map } = useMap();
  const { getAccessTokenSilently } = useAuth0();
  const [infoWindowItem, setInfoWindowItem] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [drawerDataType, setDrawerDataType] = useState("multiple");
  const [isOpen, setIsOpen] = useState(false);
  const [drawingOverlays, setDrawingOverlays] = useState([]);
  const [drawingFill, setDrawingFill] = useState("#00e1ff");
  const drawingManager = useRef(null);
  const {
    data: analyseData,
    isLoading: isAnalysisLoading,
    analyseMarker,
    analyseRectangle,
    setAnalyseMarker,
    setAnalyseRectangle,
    handleAnalyse,
  } = useAnalysis(markers, drawingManager.current);
  const { path, renderRoute, setPath } = useTransportRoute({
    markers,
    setMarkers,
  });
  const [activeRecord, setActiveRecord] = useState(null);
  const timer = useRef();
  const [modulesLoading, setModulesLoading] = useState({
    weather: false,
    newspaper: false,
    radio: false,
    news: false,
  });

  const search = async (module_type) => {
    const isAddressLookup = addressLookupModules.includes(module_type);
    if (
      moduleTypes.includes(module_type) &&
      !isAddressLookup &&
      !module_type.startsWith("ai_satellite")
    ) {
      setModuleTypes((prev) => prev.filter((item) => item !== module_type));
      setMarkers((prev) => prev.filter((item) => item.type !== module_type));
      return;
    }
    !isAddressLookup && setModuleTypes((prev) => [...prev, module_type]);
    if (module_type === "google_traffic") return;
    POPUP_MODULES.includes(module_type) &&
      setModulesLoading((prev) => ({ ...prev, [module_type]: true }));
    changeLoadingRequest("search", true);
    const startMonth = startDate ? startDate.getMonth() + 1 : 0;
    const startDay = startDate ? startDate.getDate() : 0;
    const endMonth = endDate ? endDate.getMonth() + 1 : 0;
    const endDay = endDate ? endDate.getDate() : 0;
    const request_data = {
      zoom: props.zoom,
      options: [module_type],
      keywords: keyword,
      coordinates_id: Number(workspace_id),
      // keywords: keyword,
      ...(startDate
        ? {
            date_from: `${startDate.getFullYear()}-${
              startMonth < 10 ? "0" : ""
            }${startMonth}-${startDay < 10 ? "0" : ""}${startDay}`,
          }
        : {}),
      ...(endDate
        ? {
            date_to: `${endDate.getFullYear()}-${
              endMonth < 10 ? "0" : ""
            }${endMonth}-${endDay < 10 ? "0" : ""}${endDay}`,
          }
        : {}),
      ...props.mapBounds,
      ...props.place,
      //       ...props.zoom,
    };
    try {
      const token = await getAccessTokenSilently();
      const data = await postSearch(token, request_data);
      switch (data.status) {
        case 400: {
          module_type === "weather" &&
            setModuleTypes((prev) => prev.filter((item) => item !== "weather"));
          changeLoadingRequest("search", false);
          data.message &&
            toast.custom((t) => (
              <Toast {...t} isError title="Error" subtitle={data.message} />
            ));
          break;
        }
        case 201:
        case 200: {
          props.setSearchId({ value: data["search_pk"], isOSM: false });
          const blockedModules = data["blocked_modules"];
          if (blockedModules.length > 0) {
            toast.custom((t) => (
              <Toast
                {...t}
                title="Limit Reached"
                subtitle={
                  <span>
                    Limit for modules{" "}
                    <span className="text-[var(--primary-color)] font-medium">
                      {blockedModules.join(", ")}
                    </span>{" "}
                    has been reached. Please upgrade your plan to continue using
                    this module.
                  </span>
                }
              />
            ));
          }
          break;
        }
        default:
          return;
      }
    } catch (e) {
      // Handle errors such as `login_required` and `consent_required` by re-prompting for a login
      console.error(e);
      changeLoadingRequest("search", false);
    }
  };

  const changeSelectedItem = (obj) => {
    setSelectedItem(obj);
    setInfoWindowItem(obj);
  };

  const closeDrawer = () => {
    setIsOpen(false);
    timer.current = window.setTimeout(() => {
      setDirections(null);
      setSelectedItem(null);
      setClusterItems([]);
      setPath([]);
    }, 500);
  };

  const fitBounds = (points) => {
    const bounds = new google.maps.LatLngBounds();

    points.forEach((point) => {
      const coords = new google.maps.LatLng(point.lat, point.lng);
      bounds.extend(coords);
    });

    if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
      const extendPoint1 = new google.maps.LatLng(
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng()
      );
      const extendPoint2 = new google.maps.LatLng(
        bounds.getNorthEast().lat(),
        bounds.getNorthEast().lng()
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }
    map.fitBounds(bounds);
    map.setZoom(18);
  };

  useEffect(() => {
    if (loadingRequests.search) return;
    setModulesLoading({
      newspaper: false,
      radio: false,
      news: false,
      weather: false,
    });
  }, [loadingRequests.search]);

  const tutorialMapVisible = !isFinished && step === 4;

  const { regular, cameras } = useMemo(
    () => groupMarkers(markers, showOnly),
    [markers, showOnly]
  );

  const handleGeolocate = async (e) => {
    e.preventDefault();
    if (!area) return;
    const loadingId = toast.custom((t) => (
      <Toast
        {...t}
        isLoading
        title="Geolocating..."
        subtitle="Searching for your location."
      />
    ));
    navigator.geolocation.getCurrentPosition((position) => {
      const { latitude, longitude } = position.coords;
      const coords = new google.maps.LatLng(latitude, longitude);
      const bounds = {
        north: area.north_lat,
        south: area.south_lat,
        east: area.east_lng,
        west: area.west_lng,
      };
      const spaceBounds = new google.maps.LatLngBounds(
        new google.maps.LatLng(bounds.south, bounds.west),
        new google.maps.LatLng(bounds.north, bounds.east)
      );
      const isUserWithin = spaceBounds.contains(coords);
      toast.dismiss(loadingId);
      if (isUserWithin) {
        const point = { lat: latitude, lng: longitude };
        changePlace(point);
        fitBounds([point]);
        // setCenter(point);
      } else {
        toast.custom((t) => (
          <Toast
            {...t}
            isError
            title="Outside of bounds"
            subtitle="The location you're currently in is outside of the map bounds!"
          />
        ));
      }
    });
  };

  // useEffect(() => {
  //   if (!map || !area) return;
  //   const bounds = {
  //     north: area.north_lat,
  //     south: area.south_lat,
  //     east: area.east_lng,
  //     west: area.west_lng,
  //   };
  //   const timer = window.setTimeout(
  //     () => map.setCenter(new google.maps.LatLngBounds(bounds).getCenter()),
  //     100
  //   );
  //   return () => {
  //     window.clearTimeout(timer);
  //   };
  // }, [map, area]);

  return (
    <>
      <div
        id="map-container"
        className={`h-full w-full relative flex flex-col ${
          tutorialMapVisible ? "z-30" : ""
        }`}
      >
        {analyseData && analyseData.stats && analyseData.count.total > 0 && (
          <ActiveRecordProvider
            activeRecord={activeRecord}
            changeActiveRecord={(record) => {
              setActiveRecord(record);
              fitBounds([record.location]);
            }}
          >
            <div className="overflow-hidden w-full h-full absolute inset-0">
              <StatsList {...analyseData} />
            </div>
          </ActiveRecordProvider>
        )}
        {tutorialMapVisible && (
          <StepPopup
            title="Choose your location"
            paragraph="Mark your location somewhere on the map"
            actionText="Click anywhere on the map"
            position="right-6 bottom-6"
            icon={faHandPointDown}
            iconSize="2x"
            iconPosition="right-[105%] bottom-[110%] rotate-[120deg]"
          />
        )}
        <button
          onClick={() => setAreObjectsHidden((prev) => !prev)}
          className={`map_button flex-1 transition-colors disabled:opacity-60 absolute bottom-6 left-3 z-20`}
        >
          <FontAwesomeIcon
            icon={areObjectsHidden ? faEyeSlash : faEye}
            className="on_map_icon"
          />
        </button>
        <div className="relative xl:grid grid-cols-3 2xl:absolute items-stretch self-center 2xl:max-w-none top-0 z-[1] w-full flex flex-col 2xl:flex 2xl:flex-row 2xl:w-auto xl:gap-4">
          {moduleTypes.includes("news") && (
            <NewsPopup news={news} isLoading={modulesLoading.news} />
          )}

          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <button
              title="Communication"
              disabled={loadingRequests.search}
              onClick={() => search("communication")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("communication")
                  ? "bg-[#9400D3]"
                  : "hover:bg-[#9400D3]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faTowerBroadcast}
                className="on_map_icon"
              />
            </button>
            <button
              title="Emergency"
              disabled={loadingRequests.search}
              onClick={() => search("emergency")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("emergency")
                  ? "bg-[#E0115F]"
                  : "hover:bg-[#E0115F]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faTruckMedical} className="on_map_icon" />
            </button>
            <button
              title="Military"
              disabled={loadingRequests.search}
              onClick={() => search("military")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("military")
                  ? "bg-[#9ACD32]"
                  : "hover:bg-[#9ACD32]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faPersonMilitaryRifle}
                className="on_map_icon"
              />
            </button>
            <button
              title="Power"
              disabled={loadingRequests.search}
              onClick={() => search("power")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("power")
                  ? "bg-[#00E1FF]"
                  : "hover:bg-[#00E1FF]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faBolt} className="on_map_icon" />
            </button>
{/*             <button */}
{/*               title="Sacral" */}
{/*               disabled={loadingRequests.search} */}
{/*               onClick={() => search("sacral")} */}
{/*               className={`map_button flex-1 transition-colors ${ */}
{/*                 moduleTypes.includes("sacral") */}
{/*                   ? "bg-[#FFEA00]" */}
{/*                   : "hover:bg-[#FFEA00]" */}
{/*               } disabled:opacity-60`} */}
{/*             > */}
{/*               <FontAwesomeIcon */}
{/*                 icon={faPlaceOfWorship} */}
{/*                 className="on_map_icon" */}
{/*               /> */}
{/*             </button> */}
{/*             <button */}
{/*               title="Transportation" */}
{/*               disabled={loadingRequests.search} */}
{/*               onClick={() => search("transportation")} */}
{/*               className={`map_button flex-1 transition-colors ${ */}
{/*                 moduleTypes.includes("transportation") */}
{/*                   ? "bg-[#008080]" */}
{/*                   : "hover:bg-[#008080]" */}
{/*               } disabled:opacity-60`} */}
{/*             > */}
{/*               <FontAwesomeIcon icon={faTruck} className="on_map_icon" /> */}
{/*             </button> */}
            <button
              title="Water"
              disabled={loadingRequests.search}
              onClick={() => search("water")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("water")
                  ? "bg-[#0064d7]"
                  : "hover:bg-[#0064d7]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faDroplet} className="on_map_icon" />
            </button>
            <button
              title="No fly zones"
              disabled={loadingRequests.search}
              onClick={() => search("nofly_zone")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("nofly_zone")
                   ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <NoflyIcon className="on_map_icon" />
            </button>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <HoverCard openDelay={0}>
              <HoverCardTrigger asChild>
                <button
                  title="AI Satellite image analyse"
                  disabled={loadingRequests.search}
                  className={cn(
                    "map_button flex-1 transition-colors disabled:opacity-60",
                    moduleTypes.some((item) =>
                      item.startsWith("ai_satellite")
                        ? "bg-[var(--primary-color)]"
                        : "hover:bg-[var(--primary-color)]"
                    )
                  )}
                >
                  <FontAwesomeIcon icon={faSatellite} className="on_map_icon" />
                </button>
              </HoverCardTrigger>
              <HoverCardContent align="start" className="w-40">
                <ul>
                  {[
                    "Arcgis",
                    "Bing",
                    "Google",
                    "Here",
                    "Landsat",
                    "Maptiler",
                    "Mapbox",
                    "Nasa",
                  ].map((title) => (
                    <li key={`list-ai-satellite-${title}`}>
                      <button
                        disabled={loadingRequests.search}
                        className={cn(
                          "flex items-center gap-2 cursor-pointer px-2 py-1 rounded text-base text-left hover:bg-blue/5 w-full"
                        )}
                        onClick={() =>
                          search(`ai_satellite_${title.toLowerCase()}`)
                        }
                        key={`ai-satellite-${title}`}
                      >
                        {title}

                        {(title === "Google" ||
                          title === "Maptiler" ||
                          title === "Here" ||
                          title === "Nasa") && (
                          <div className="min-w-3 w-3 h-3 grid place-content-center">
                            <FontAwesomeIcon icon={faLocationDot} />
                          </div>
                        )}
                        {(title === "Arcgis" ||
                          title === "Bing" ||
                          title === "Landsat" ||
                          title === "Mapbox") && (
                          <div className="min-w-3 w-3 h-3 grid place-content-center">
                            <FontAwesomeIcon icon={faUsersViewfinder} />
                          </div>
                        )}
                        {(title === "Nasa" || title === "Landsat") && (
                          <div className="min-w-3 w-3 h-3 grid place-content-center">
                            <FontAwesomeIcon icon={faClock} />
                          </div>
                        )}
                      </button>
                    </li>
                  ))}
                </ul>
              </HoverCardContent>
            </HoverCard>
{/*             <button */}
{/*               title="Location" */}
{/*               onClick={handleGeolocate} */}
{/*               className={`map_button flex-1 hover:bg-[var(--primary-color)] active:opacity-80 transition-opacity disabled:opacity-60`} */}
{/*             > */}
{/*               <FontAwesomeIcon icon={faLocationDot} className="on_map_icon" /> */}
{/*             </button> */}
            <button
              title="Satellite Timelapse. Define timestamp range"
              disabled={loadingRequests.search}
              onClick={() => search("timelapse")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("timelapse")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faSatellite} className="on_map_icon" />
            </button>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
              <button
              title="Fire"
              disabled={loadingRequests.search}
              onClick={() => search("fire")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("fire")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faFire}
                className="on_map_icon"
              />
            </button>
            <button
              title="Disasters"
              disabled={loadingRequests.search}
              onClick={() => search("disasters")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("disasters")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faHouseFloodWater}
                className="on_map_icon"
              />
            </button>

            <button
              title="Weather"
              disabled={loadingRequests.search}
              onClick={() => search("weather")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("weather")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faSun} className="on_map_icon" />
            </button>
          </div>

          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <button
              title="News"
              disabled={loadingRequests.search}
              onClick={() => search("news")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("news")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faNewspaper} className="on_map_icon" />
            </button>
            <button
              title="Radio"
              disabled={loadingRequests.search}
              onClick={() => search("radio")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("radio")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faRadio} className="on_map_icon" />
            </button>
            <button
              title="Newspapers"
              disabled={loadingRequests.search}
              onClick={() => search("newspaper")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("newspaper")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faLink} className="on_map_icon" />
            </button>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <button
              title="Analyse"
              onClick={() => setAnalyseMarker((prev) => !prev)}
              className={`map_button flex-1 active:opacity-80 transition-opacity disabled:opacity-60 ${
                analyseMarker
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              }`}
            >
              <FontAwesomeIcon
                icon={faMagnifyingGlassChart}
                className="on_map_icon"
              />
            </button>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <button
              title="Address lookup"
              disabled={loadingRequests.search}
              onClick={() => search("trestle")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("trestle")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faHouseChimneyUser}
                className="on_map_icon"
              />
            </button>
            <button
              title="Google places"
              disabled={loadingRequests.search}
              onClick={() => search("google_places")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("google_places")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-google"
                className="on_map_icon"
              />
            </button>
          </div>
          <div
            className={cn(
              "absolute top-full left-0 right-0 2xl:max-w-max w-full 2xl:left-1/2 2xl:right-auto 2xl:-translate-x-1/2"
            )}
          >
            <div className="flex items-start w-full min-w-full max-w-max">
              {moduleTypes.includes("weather") && (
                <WeatherPopup isLoading={modulesLoading.weather} />
              )}
              {moduleTypes.includes("radio") && (
                <Radio isLoading={modulesLoading.radio} />
              )}
              {moduleTypes.includes("newspaper") && (
                <Newspapers isLoading={modulesLoading.newspaper} />
              )}
            </div>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
              <button
              title="Ports"
              disabled={loadingRequests.search}
              onClick={() => search("ports")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("ports")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faAnchor} className="on_map_icon" />
            </button>
            <button
              title="Airports"
              disabled={loadingRequests.search}
              onClick={() => search("airports")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("airports")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faPlaneArrival} className="on_map_icon" />
            </button>
            <button
              title="Stadiums"
              disabled={loadingRequests.search}
              onClick={() => search("stadium")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("stadium")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faFutbol} className="on_map_icon" />
            </button>
            <button
              title="Stores"
              disabled={loadingRequests.search}
              onClick={() => search("stores")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("stores")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faStore} className="on_map_icon" />
            </button>
          </div>
          <div className="flex w-full border border-slate-300 dark:border-[#1F242F]">
            <button
              title="Traffic"
              disabled={loadingRequests.search}
              onClick={() => search("google_traffic")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("google_traffic")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faCar} className="on_map_icon" />
            </button>
            <button
              title="Incidents"
              disabled={loadingRequests.search}
              onClick={() => search("incidents")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("incidents")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="on_map_icon"
              />
            </button>
            <button
              title="Vessels"
              disabled={loadingRequests.search}
              onClick={() => search("vessel")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("vessel")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faShip} className="on_map_icon" />
            </button>
            <button
              title="Planes"
              disabled={loadingRequests.search}
              onClick={() => search("planes")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("planes")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faPlane} className="on_map_icon" />
            </button>

            <button
              title="Amtrak"
              disabled={loadingRequests.search}
              onClick={() => search("amtrak")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("amtrak")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon icon={faTrain} className="on_map_icon" />
            </button>
            <button
              title="Waze"
              disabled={loadingRequests.search}
              onClick={() => search("waze")}
              className={`map_button flex-1 transition-colors ${
                moduleTypes.includes("waze")
                  ? "bg-[var(--primary-color)]"
                  : "hover:bg-[var(--primary-color)]"
              } disabled:opacity-60`}
            >
              <FontAwesomeIcon
                icon="fa-brands fa-solid fa-waze"
                className="on_map_icon"
              />
            </button>
          </div>
        </div>
        <div className="relative overflow-hidden flex-1">
          {area && (
            <Map
              changeMapBounds={props.changeMapBounds}
              isTraffic={moduleTypes.includes("google_traffic")}
              zoom={11}
              place={props.place}
              setPlace={(coords) => changePlace(coords)}
              areObjectsHidden={areObjectsHidden}
              onLoad={(map) => {
                if (!area) return;
                const bounds = {
                  north: area.north_lat,
                  south: area.south_lat,
                  east: area.east_lng,
                  west: area.west_lng,
                };
                map.setCenter(new google.maps.LatLngBounds(bounds).getCenter());
              }}
              defaultOptions={
                area
                  ? {
                      restriction: {
                        latLngBounds: {
                          north: area.north_lat,
                          south: area.south_lat,
                          east: area.east_lng,
                          west: area.west_lng,
                        },
                        strictBounds: true,
                      },
                    }
                  : {}
              }
              // center={new google.maps.LatLngBounds(bounds).getCenter()}
            >
              <DrawingManager
                onLoad={(manager) => (drawingManager.current = manager)}
                options={getDrawingManagerOptions(
                  analyseMarker ? "#FF00CD" : drawingFill,
                  !analyseMarker
                )}
                onOverlayComplete={(e) => {
                  // clear prev rect
                  analyseRectangle && analyseRectangle.setMap(null);
                  // set overlay depending on type and if analysed
                  if (e.type === "rectangle" && analyseMarker) {
                    setAnalyseRectangle(e.overlay);
                  } else {
                    setDrawingOverlays((prev) => [...prev, e.overlay]);
                  }
                  e.overlay.addListener("click", ({ latLng }) => {
                    const location = {
                      lat: latLng.lat(),
                      lng: latLng.lng(),
                    };
                    changePlace(location);
                  });
                }}
              />
              {path && path.length > 0 && (
                <Polyline
                  path={path}
                  geodesic={true}
                  options={{
                    strokeOpacity: 1,
                    strokeWeight: 2,
                    strokeColor: "#00e1ff",
                  }}
                />
              )}
              {analyseRectangle && analyseMarker && (
                <InfoBox
                  zIndex={10}
                  position={getCenterFromBounds(analyseRectangle.getBounds())}
                >
                  {isAnalysisLoading ? (
                    <LoaderIcon className="animate-spin mx-auto" />
                  ) : (
                    <button
                      onClick={handleAnalyse}
                      className="bg-[var(--primary-color)] py-2 px-4 text-white rounded"
                    >
                      Analyse
                    </button>
                  )}
                </InfoBox>
              )}
              <div className="absolute right-1 top-8 flex items-center h-8 gap-1">
                <button className="group relative dark:border-[#376187] border-[var(--pink-color)] border-[1px] text-[var(--input-font)] bg-pink-shade dark:bg-[var(--primary-color-light)] h-full p-1 grid place-content-center">
                  <div
                    style={{ backgroundColor: drawingFill }}
                    className="rounded-full h-5 w-5 border-[1px] dark:border-[#376187] border-[var(--pink-color)]"
                  ></div>
                  <div className="absolute top-full group-hover:pointer-events-auto pointer-events-none group-hover:opacity-100 opacity-0 transition-opacity right-0 dark:border-[#376187] border-[var(--pink-color)] border-[1px] text-[var(--input-font)] bg-pink-shade dark:bg-[var(--primary-color-light)] p-4 rounded-sm">
                    <HexColorPicker
                      color={drawingFill}
                      onChange={(color) => setDrawingFill(color)}
                    />
                  </div>
                </button>

                {drawingOverlays.length > 0 && !analyseMarker && (
                  <Fragment>
                    <Button
                      className="!py-1.5 !px-2.5 !h-8"
                      onClick={() => {
                        const lastOverlay = [...drawingOverlays].pop();
                        lastOverlay.setMap(null);
                        setDrawingOverlays((prev) => {
                          const newArr = [...prev];
                          newArr.pop();
                          return newArr;
                        });
                      }}
                      icon={faUndo}
                    >
                      Undo
                    </Button>
                    <Button
                      className="!py-1.5 !px-2.5 !h-8"
                      onClick={() => {
                        drawingOverlays.forEach((overlay) =>
                          overlay.setMap(null)
                        );
                        setDrawingOverlays([]);
                      }}
                      icon={faX}
                    >
                      Clear
                    </Button>
                  </Fragment>
                )}
              </div>
              {regular.map(({ type, objects }, i) => {
                const polygonModule = POLYGON_MODULES.find(
                  (item) => item.value === type
                );
                return polygonModule ? (
                  objects.map((obj) => {
                    return (
                      <PolygonF
                        paths={JSON.parse(obj.polygon)}
                        options={{
                          fillColor: polygonModule.color,
                          fillOpacity: 0.7,
                          strokeColor: polygonModule.color,
                        }}
                        onClick={(e) => {
                          timer.current && window.clearTimeout(timer.current);
                          const location = {
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          };
                          changePlace(location);
                          changeSelectedItem({
                            ...obj,
                            location,
                          });
                          setDrawerDataType("single");
                          setIsOpen(true);
                          const points = JSON.parse(obj.polygon);
                          fitBounds([...points, location]);
                        }}
                        key={obj.id}
                      />
                    );
                  })
                ) : (
                  <MarkerClustererF
                    options={{
                      styles: [
                        {
                          backgroundPosition: "3% -14%",
                          height: 74,
                          textColor: "#FFFFFF",
                          width: 74,
                          url: clusterIcons[type],
                        },
                      ],
                    }}
                    onClick={(cluster) => {
                      timer.current && window.clearTimeout(timer.current);
                      const markers = cluster.getMarkers();
                      setDirections(null);
                      setClusterItems(markers.map((marker) => marker.item));
                      setDrawerDataType("multiple");
                      setIsOpen(true);
                    }}
                    key={type + i}
                  >
                    {(clusterer) =>
                      objects.map((obj) => (
                        <CustomMarker
                          {...obj}
                          clusterer={clusterer}
                          position={obj.location}
                          onClick={() => {
                            timer.current && window.clearTimeout(timer.current);
                            if (TRANSPORT_TYPES.includes(type)) {
                              renderRoute({ item_id: obj.id, type });
                            } else if (DIRECTIONS_MODULES.includes(type)) {
                              (async () => {
                                const dirs = await renderDirections(obj);
                                setDirections(dirs);
                              })();
                            } else {
                              setDirections(null);
                              setPath([]);
                            }
                            changeSelectedItem(obj);
                            setDrawerDataType("single");
                            setIsOpen(true);
                          }}
                          isActive={
                            activeRecord &&
                            activeRecord.id === obj.id &&
                            activeRecord.type === obj.type
                          }
                          isAnimating={
                            drawerDataType === "single" && selectedItem
                              ? selectedItem.id === obj.id &&
                                selectedItem.type === obj.type
                              : focusedItem &&
                                focusedItem.id === obj.id &&
                                focusedItem.type === obj.type
                          }
                          key={type + obj.id}
                        >
                          {drawerDataType === "single" &&
                            infoWindowItem &&
                            ["airports", "stadium", "stores", "ports"].includes(
                              obj.type
                            ) &&
                            obj.id === infoWindowItem.id && (
                              <InfoWindowF
                                onCloseClick={() => setInfoWindowItem(null)}
                                options={{
                                  ariaLabel: "custom-window-marker",
                                }}
                              >
                                {obj.type === "ports" ? (
                                  <Ports {...obj} />
                                ) : obj.type === "airports" ? (
                                  <Airport {...obj} />
                                ) : obj.type === "stadium" ? (
                                  <Stadium {...obj} />
                                ) : (
                                  <Store {...obj} />
                                )}
                              </InfoWindowF>
                            )}
                        </CustomMarker>
                      ))
                    }
                  </MarkerClustererF>
                );
              })}
              <MarkerClustererF
                options={{
                  styles: [
                    {
                      backgroundPosition: "3% -14%",
                      height: 74,
                      textColor: "#FFFFFF",
                      width: 74,
                      url: clusterIcons["iot"],
                    },
                  ],
                }}
                onClick={(cluster) => {
                  timer.current && window.clearTimeout(timer.current);
                  const markers = cluster.getMarkers();
                  setDirections(null);
                  setClusterItems(markers.map((marker) => marker.item));
                  setDrawerDataType("multiple");
                  setIsOpen(true);
                }}
              >
                {(clusterer) =>
                  cameras
                    .flatMap((item) => item.objects)
                    .map((obj, i) => (
                      <CustomMarker
                        {...obj}
                        clusterer={clusterer}
                        position={obj.location}
                        onClick={() => {
                          timer.current && window.clearTimeout(timer.current);
                          setDirections(null);
                          changeSelectedItem(obj);
                          setDrawerDataType("single");
                          setIsOpen(true);
                        }}
                        isActive={
                          activeRecord &&
                          activeRecord.id === obj.id &&
                          activeRecord.type === obj.type
                        }
                        isAnimating={
                          drawerDataType === "single" && selectedItem
                            ? selectedItem.id === obj.id &&
                              selectedItem.type === obj.type
                            : focusedItem &&
                              focusedItem.id === obj.id &&
                              focusedItem.type === obj.type
                        }
                        key={obj.type + i}
                      />
                    ))
                }
              </MarkerClustererF>
              {directions && <DirectionsRenderer directions={directions} />}
            </Map>
          )}
          <Drawer
            isOpen={isOpen}
            handleClose={closeDrawer}
            isSingle={drawerDataType === "single"}
            module={
              drawerDataType === "single" && selectedItem
                ? selectedItem.type
                : clusterItems[0]?.type
            }
          >
            {drawerDataType === "single"
              ? selectedItem && (
                  <DrawerItem
                    item={selectedItem}
                    isFullHeight
                    position={selectedItem.location}
                  />
                )
              : clusterItems.map((item, i) => (
                  <DrawerItem
                    item={item}
                    onMouseEnter={() => setFocusedItem(item)}
                    onMouseLeave={() => setFocusedItem(null)}
                    key={`${item.id}${i}`}
                  />
                ))}
          </Drawer>
        </div>
      </div>
      {tutorialMapVisible && (
        <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-20" />
      )}
    </>
  );
}
