import { useEffect, useRef, useState } from "react";
import { changeLocation } from "../../utils/single-item/lib";
import toast from "react-hot-toast";
import Toast from "../../components/toast";
import { useParams } from "react-router-dom";

export default function useLocation(item?: SingleRecord) {
  const { type, item_id, workspace_id, hashtag_id } = useParams();
  const bounds = useRef<google.maps.LatLngBounds | null>(null);
  const [location, setLocation] = useState<Coords | undefined>(item?.location);
  const [chosenPlace, setChosenPlace] = useState<Coords | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  // const [searchBox, setSearchBox] =
  //   useState<google.maps.places.SearchBox | null>(null);

  async function handleChange() {
    if (!chosenPlace) return;
    if (!!hashtag_id && !item) return;
    if (!hashtag_id && !workspace_id && !item_id && !type) return;
    setIsLoading(true);
    const loading = toast.custom((t) => (
      <Toast
        {...t}
        title="Changing location"
        subtitle="Location is being changed for the given object"
      />
    ));
    const { error } = await changeLocation({
      item_id: hashtag_id ? item!.id : item_id,
      item_type: hashtag_id ? item!.type : type,
      ...chosenPlace,
      ...(hashtag_id
        ? { workspace_type: "hashtag", hashtag_id}
        : { workspace_id }),
    });
    setIsLoading(false);
    toast.dismiss(loading);
    if (error) {
      toast.custom((t) => (
        <Toast {...t} isError title="Error" subtitle={error} />
      ));
    } else {
      setLocation(chosenPlace);
      setChosenPlace(null);
    }
  }

  const onPlaceChange = (coords: Coords) => setChosenPlace(coords);

  // const onSearchBoxLoad = (box: google.maps.places.SearchBox) =>
  //   setSearchBox(box);

  // function onSearch() {
  //   if (!searchBox) return;
  //   const query = searchBox.getPlaces();
  //   if (!query) return;
  //   const lat = query[0].geometry?.location?.lat() as number;
  //   const lng = query[0].geometry?.location?.lng() as number;
  //   setChosenPlace({ lat, lng });
  //   map?.setCenter({ lat, lng });
  // }

  useEffect(() => {
    if (!item?.location) return;
    setLocation(item.location);
  }, [item?.location]);

  return {
    isLoading,
    location,
    // bounds: bounds.current,
    chosenPlace,
    onPlaceChange,
    handleChange,
    // onSearch,
    // onSearchBoxLoad,
    setBounds: (bnds: google.maps.LatLngBounds | null) =>
      (bounds.current = bnds),
  };
}
