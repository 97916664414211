import axios from "axios";
import useSWRInfinite, { SWRInfiniteResponse } from "swr/infinite";

export const HASHTAG_MODULES = [
  "instagram",
  "twitter",
  "tiktok",
  "youtube",
  "threads",
  "telegram",
] as const;

type HashtagPosts = {
  current_page: number;
  total_pages: number;
  data: SingleRecord[];
  hashtag: string;
};

async function getHashtagPosts(
  id: string,
  moduleName: string,
  page: number
): Promise<HashtagPosts> {
  // const { data } = await axios.get(`/getHashtagPosts/${id}/${moduleName}`);
  const { data } = await axios.get(`/getHashtagPosts/${moduleName}/${id}`, {
    params: {
      page,
    },
  });
  if (data.error) {
    throw new Error(data.message);
  }
  return data;
}

const useInfinitePosts = (name: string, id?: string) => {
  const getKey = (index: number, prevData: HashtagPosts) => {
    if (index === 0) return id ? ["hashtag-search", name, id, 1] : null;
    if (prevData && index >= prevData.total_pages) return null;
    return id ? ["hashtag-search", name, id, index + 1] : null;
  };
  return useSWRInfinite(
    getKey,
    ([, moduleName, hashtagId, page]) =>
      getHashtagPosts(
        moduleName as string,
        hashtagId as string,
        page as number
      ),
    {
      revalidateOnFocus: false,
      revalidateAll: true,
      // revalidateAll: false,
      // revalidateFirstPage: false,
    }
  );
};
type Response = {
  [N in (typeof HASHTAG_MODULES)[number]]: SWRInfiniteResponse<HashtagPosts>;
};

export default function useHashtagPosts(id: string): Response {
  const instagram = useInfinitePosts(id, "instagram");
  const twitter = useInfinitePosts(id, "twitter");
  const tiktok = useInfinitePosts(id, "tiktok");
  const youtube = useInfinitePosts(id, "youtube");
  const threads = useInfinitePosts(id, "threads");
  const telegram = useInfinitePosts(id, "telegram");

  return {
    instagram,
    twitter,
    tiktok,
    youtube,
    threads,
    telegram,
  };
  // return useSWR(
  //   id ? ["hashtag-posts", id] : null,
  //   ([, hashtagId]) => getHashtagPosts(hashtagId),
  //   {
  //     revalidateOnFocus: false,
  //   }
  // );
}
