import axios from "axios";
import useSWR from "swr";

export default function Ticker() {
  const { data: events } = useSWR(["global-incidents"], () =>
    axios.get("/globalIncidentAll/").then((res) => res.data[0].objects)
  );

  if (!Array.isArray(events) || events.length === 0) return <></>;

  return (
    <div className="h-[54px]">
      <div className="bg-sidebar py-1.5 border-y border-border absolute inset-x-0 w-full top-4 flex overflow-hidden [--duration:60s]">
        <div className="flex shrink-0 animate-marquee">
          {events.map((event) => (
            <div className="flex items-center">
              <a
                href={event.url}
                target="_blank"
                rel="noreferrer"
                className="text-font text-lg"
              >
                {event.title}
              </a>
              <span className="px-4 text-font opacity-75">|</span>
            </div>
          ))}
        </div>
        <div className="flex shrink-0 animate-marquee">
          {events.map((event) => (
            <div className="flex items-center">
              <a
                href={event.url}
                target="_blank"
                rel="noreferrer"
                className="text-font text-lg"
              >
                {event.title}
              </a>
              <span className="px-4 text-font opacity-75">|</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
