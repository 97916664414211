import axios, { isAxiosError } from "axios";

export async function getAuthorSearchId(
  token: string,
  itemType: string,
  itemId: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/GetPeople/${itemType}/${itemId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const data = await response.json();

  if (data.error) {
    throw new Error(data.message || "Error");
  }

  return data.task_id;
}

export async function getAIGeolocationId(
  body: Record<string, any>
): Promise<ServerResponse<TaskId>> {
  try {
    const { data } = await axios.post("/geolocate-ai/", body);

    if (data.error) {
      return {
        results: [],
        error: data.message,
      };
    }

    return {
      results: [{ task_id: data.task_id }],
    };
  } catch (err) {
    return {
      results: [],
      error: "Unknown error",
    };
  }
}

export async function getFaceSearch(
  body: any
): Promise<ServerResponse<TaskId>> {
  try {
    const { data } = await axios.post(`/faceSearch`, body);

    if (data.error) {
      // check if it returns "error" key or "task_id" key
      return {
        results: [],
        error: data.message,
      };
    }

    return {
      results: [data],
    };
  } catch (err: any) {
    return {
      results: [],
      error: err.response?.data?.message || "Unknown error",
    };
  }
}

export async function getTaskInfo<T>(
  searchId: string
): Promise<ServerResponse<TaskInfo<T>>> {
  try {
    const { data } = await axios.get(`/get-task-info/${searchId}`);
    return {
      results: [data],
    };
  } catch (err) {
    return {
      error:
        typeof err === "string" ? err : "An error occured, try again later!",
      results: [],
    };
  }
}

export async function postLike(
  token: string,
  workspaceId: string,
  itemType: string,
  itemId: string
) {
  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER_URL}/api/addToFavorites/${workspaceId}/${itemType}/${itemId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
  const data = await response.json();
  return data;
}

export async function changeLocation(body: Record<string, any>) {
  try {
    await axios.post(`/changeCoordinates`, body);

    return {
      results: [],
    };
  } catch (err) {
    return {
      error:
        typeof err === "string" ? err : "An error occured, try again later!",
      results: [],
    };
  }
}
