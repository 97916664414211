import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";
import AddLocation from "./pages/add-location";
import Database from "./pages/database";
import SingleItem from "./pages/single-item";
import TileItems from "./pages/tile-items";
import Settings from "./pages/settings";
import Sidebar from "./components/sidebar";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import {
  faMap,
  faMoon,
  faSun,
  faCircleDot,
} from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faChevronLeft,
  faPowerOff,
  faLocationCrosshairs,
  faMagnifyingGlass,
  faDatabase,
  faTrash,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { useEffect, useLayoutEffect } from "react";
import toast, { useToasterStore } from "react-hot-toast";
import TutorialProvider from "./providers/tutorial";
import EntryPopup from "./components/tutorial/entry";
import SearchDatabase from "./pages/search-database";
import SearchUsername from "./pages/search_username";
import MapsProvider from "./providers/MapsProvider";
import Timeline from "./pages/timeline";
import { useTheme } from "./providers/theme";
import { FiltersProvider } from "./providers/search/filters";
import { waveform } from "ldrs";
import Home from "./pages/home";
import Subscription from "./pages/subscription";
import Reservoir from "./pages/reservoir";
import Search from "./pages/search";
import axios from "axios";
import { cn } from "./utils/cn";
import DailyDashboard from "./pages/daily-dashboard";
import UploadPage from "./pages/upload";
import PersonInvestigation from "./pages/person-investigation";
import PeopleInvestigated from "./pages/people-investigated";
import HashtagSearch from "./pages/hashtag-search";
import HashtagPage from "./pages/hashtag";

library.add(
  fab,
  faBars,
  faMap,
  faChevronLeft,
  faPowerOff,
  faMoon,
  faSun,
  faLocationCrosshairs,
  faCircleDot,
  faMagnifyingGlass,
  faDatabase,
  faTrash,
  faHome
);

waveform.register();

const TOAST_LIMIT = 4;

function App() {
  const { toasts } = useToasterStore();
  const { isDark } = useTheme();
  const { pathname } = useLocation();
  const {
    isLoading,
    isAuthenticated,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
  } = useAuth0();

  useEffect(() => {
    toasts
      .filter((t) => t.visible) // Only consider visible toasts
      .filter((_, i) => i >= TOAST_LIMIT) // Is toast index over limit?
      .forEach((t) => toast.dismiss(t.id)); // Dismiss – Use toast.remove(t.id) for no exit animation
  }, [toasts]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, [pathname]);

  if (isAuthenticated) {
    axios.interceptors.request.use(
      async (config) => {
        try {
          const token = await getAccessTokenSilently();
          config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
          console.error("Failed to fetch access token:", error);
        }
        return config;
      },
      (error) => {
        // Handle request errors
        return Promise.reject(error);
      }
    );
  } else {
    axios.interceptors.request.clear();
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center bg-body dark:bg-[#101921]">
        <l-waveform size={48} color="white" />
      </div>
    );
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (!isAuthenticated) {
    loginWithRedirect({
      appState: {
        returnTo: window.location.pathname,
      },
    });
    return <></>;
  }

  return (
    <div
      className={cn(
        "layout min-h-full grid grid-cols-[max-content_1fr]",
        isDark && "dark"
      )}
    >
      <TutorialProvider>
        <Sidebar />
        <Routes>
          <Route path="/" element={<Subscription />} />
          <Route
            path="/:workspace_id/dashboard"
            element={
              <MapsProvider>
                <Home />
              </MapsProvider>
            }
          />
          <Route
            path="/:workspace_id/timeline"
            element={
              <MapsProvider>
                <Timeline />
              </MapsProvider>
            }
          />
          <Route
            path="/:workspace_id/daily-dashboard"
            element={
              <MapsProvider>
                <DailyDashboard />
              </MapsProvider>
            }
          />
          <Route
            path="/add-location"
            element={
              <MapsProvider>
                <AddLocation />
              </MapsProvider>
            }
          />
          <Route path="/reservoir" element={<Reservoir />} />
          <Route
            path="/:workspace_id/search"
            element={
              <MapsProvider>
                <FiltersProvider>
                  <Search />
                </FiltersProvider>
              </MapsProvider>
            }
          />
          <Route path="/database" element={<Database />} />
          <Route
            path="/search-database"
            element={
              <MapsProvider>
                <SearchDatabase />
              </MapsProvider>
            }
          />
          {/*}<Route path="/search-username" element={<SearchUsername />} /> */}
          <Route path="/hashtag-search" element={<HashtagSearch />} />
          <Route path="/hashtag-search/:id" element={<HashtagPage />} />
          <Route
            path="/hashtag-search/:hashtag_id/:type/:item_id"
            element={
              <MapsProvider>
                <SingleItem />
              </MapsProvider>
            }
          />
          <Route path="/people-investigated" element={<PeopleInvestigated />} />
          <Route path="/geolocate-photo" element={<UploadPage from_photo />} />
          <Route
            path="/upload-locations"
            element={<UploadPage from_upload />}
          />
          <Route path="/ai-search" element={<UploadPage from_ai />} />
          <Route path="*" element={<Navigate to="/database" />} />
          <Route
            path="/:workspace_id/module/:type"
            element={
              <MapsProvider>
                <TileItems />
              </MapsProvider>
            }
          />
          <Route
            path="/:workspace_id/module/:type/item/:item_id"
            element={
              <MapsProvider>
                <SingleItem />
              </MapsProvider>
            }
          />
          <Route
            path="/people-investigated/:person_id"
            element={
              <MapsProvider>
                <PersonInvestigation />
              </MapsProvider>
            }
          />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/callback" element={<Callback />} /> */}
        </Routes>
        <EntryPopup />
      </TutorialProvider>
    </div>
  );
}

export default App;
