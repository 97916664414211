import { SEARCH_OPTIONS } from "@/const/search";
import {
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
  Accordion,
} from "@/components/ui/accordion";
import TypeAccordion from "./accordion";
import useDailyDashboard from "@/lib/daily-dashboard";
import { useParams } from "react-router-dom";

const eventNames = [
  "crimes",
  "crimes2",
  "amber_alerts",
  "events1",
  "events2",
  "events3",
  "events4",
  "events5",
  "global_incident",
  "uk_stopsearch",
  "fire",
  "uk_police"
];

export default function CrimeEvents({ date }: DailyDashboardParams) {
  const { workspace_id } = useParams() as Readonly<{ workspace_id: string }>;
  const { data } = useDailyDashboard(workspace_id, date);
  return (
    <AccordionItem className="px-4" value="crimes">
      <AccordionTrigger>
        <div />
        <span>Crimes</span>
        <span className="opacity-60 font-normal">
          {data?.statistics.crime_events || "0"}
        </span>
      </AccordionTrigger>
      <AccordionContent>
        {data?.objects.crime_events && data.objects.crime_events.length > 0 ? (
          <Accordion collapsible type="single">
            {SEARCH_OPTIONS.find((item) => item.title === "Events")
              ?.options.filter(
                (opt) =>
                  eventNames.includes(opt.value) &&
                  data.objects.crime_events.some(
                    (obj) => obj.type === opt.value
                  )
              )
              .map((opt) => (
                <TypeAccordion
                  name={opt.value}
                  title={opt.label}
                  objects={data.objects.crime_events.filter(
                    (obj) => obj.type === opt.value
                  )}
                  key={opt.value}
                />
              ))}
          </Accordion>
        ) : (
          <div className="py-2">
            <p className="text-font text-sm opacity-75 text-center">
              No results found
            </p>
          </div>
        )}
      </AccordionContent>
    </AccordionItem>
  );
}
