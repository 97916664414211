import { useEffect, useRef, useState } from "react";
import isImgUrl from "../../utils/isImgUrl";
import { Link, useParams } from "react-router-dom";
import { faStar as faStarSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as faStarRegular } from "@fortawesome/free-regular-svg-icons";
import { AUTHOR_EXCLUSIONS, FIELD_COL_SPAN } from "../../const/single-item";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ICON_TYPES } from "../../const/search";
import useFavorite from "../../hooks/single-item/useFavorite";
import HlsPlayer from "react-hls-player";
import { Card } from "../ui/card";
import Attributes from "../ui/attributes";
import { Button } from "../ui/button";
import { cn } from "@/utils/cn";
import Markdown from "react-markdown";
import markdownComponents from "@/const/mdx-components";
import { Tweet } from "react-tweet";
import { useTheme } from "@/providers/theme";

const EMBED_TYPES = ["twitter", "tiktok", "youtube", "instagram"];

const EmbedPost = ({ type, url }) => {
  const [embedData, setEmbedData] = useState(null);
  const { isDark } = useTheme();

  useEffect(() => {
    if (type !== "youtube") return;
    (async () => {
      const res = await fetch(
        `https://youtube.com/oembed?url=${url}&format=json`
      );
      if (res.ok) {
        const data = await res.json();
        setEmbedData(data);
      } else {
        console.warn("Couldn't load youtube embed: ", res.statusText);
      }
    })();
  }, [type, url]);

  switch (type) {
    case "twitter":
      return (
        <div data-theme={isDark ? "dark" : "light"}>
          <Tweet id={url.split("/").pop()} />
        </div>
      );
    case "tiktok":
      return (
        <iframe
          className="min-h-96"
          title={url}
          src={`https://www.tiktok.com/embed/v3/${url.split("/").pop()}`}
        ></iframe>
      );
    case "youtube":
      if (!embedData?.html) return <></>;
      return (
        <div
          className="[&>iframe]:!w-full [&>iframe]:!min-h-56"
          dangerouslySetInnerHTML={{ __html: embedData.html }}
        />
      );
    case "instagram":
      if (url.includes("explore/location"))
      {
        return <></>;
      }
      else {
        return (
            <iframe
                className="w-full overflow-hidden aspect-[2/3]"
                title={url}
                scrolling="no"
                src={url + "/embed"}
            />
        );
      }
    default:
      return <></>;
  }
};

export default function ObjectRef({
  person = null,
  people = [],
  authorDataEnabled = false,
  workspaceId = null,
  workspaceTitle = null,
  showTypeIcon = true,
  exclusionsType = "tile",
  simplified = false,
  is_favorite = false,
  children = undefined,
  href = "",
  ...props
}) {
  const isM3U8 = props.photo_url && props.photo_url.endsWith(".m3u8");
  const { workspace_id } = useParams();
  const [isUrlAnImage, setIsUrlAnImage] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const { isFavorite, handleFavorite } = useFavorite(
    {
      type: props.type,
      item_id: props.id,
      workspace_id: workspaceId || workspace_id,
    },
    is_favorite
  );

  useEffect(() => {
    if (!props.photo_url) return;
    (async () => {
      const isImage = await isImgUrl(props.photo_url);
      isImage ? setIsUrlAnImage(true) : setIsUrlAnImage(false);
    })();
  }, [props.photo_url]);

  const doesPhotoExist = props.photo_url || props.screenshot || props.timelapse;
  const title = props.title || props.name;

  const description = props.description || props.text || props.summary;
  props.type === "instagram" && console.log(props);
  return (
    <Card
      id={props.id}
      className={cn(
        "group shadow-[0_3px_15px_rgba(#333,0.2)] relative w-full max-w-full",
        simplified && props.type === "snapchat"
          ? "max-h-[calc(100vh-114px)]"
          : "self-start"
      )}
    >
      <div className="flex items-center justify-between">
        {/*<p className="text-xs text-[var(--text-color)] font-bold relative z-10">{props.timestamp}</p>*/}
        <div className="flex items-center gap-2">
          {showTypeIcon && (
            <div
              className={cn(
                "relative rounded h-8 w-8 flex items-center justify-center overflow-hidden"
              )}
            >
              <img
                className="max-w-[60%] relative z-10"
                src={ICON_TYPES[props.type]}
                alt=""
              />
              <div className="absolute opacity-80 backdrop-blur-sm inset-0 w-full h-full bg-[var(--sidebar-color)]" />
            </div>
          )}
          {props.timestamp && (
            <div className="text-xs text-[var(--text-color)] font-bold relative z-10 flex items-center justify-center">
              {new Intl.DateTimeFormat("en-US", {
                dateStyle: "medium",
                timeStyle: "short",
                timeZone: "UTC",
              }).format(new Date(props.timestamp).getTime())}{" "}
              UTC
            </div>
          )}
        </div>
        {(workspaceId || workspace_id) && (
          <button
            onClick={handleFavorite}
            className={`relative z-20 rounded h-8 w-8 grid place-content-center ${
              isFavorite
                ? "text-[var(--primary-color2)]"
                : "text-[var(--text-color)] hover:text-[var(--primary-color2)]"
            } overflow-hidden`}
          >
            <FontAwesomeIcon
              className="relative z-10"
              icon={isFavorite ? faStarSolid : faStarRegular}
              size="xs"
            />
            <div className="absolute opacity-80 backdrop-blur-sm inset-0 w-full h-full bg-[var(--sidebar-color)]" />
          </button>
        )}
      </div>
      {EMBED_TYPES.includes(props.type) ? (
        <EmbedPost type={props.type} url={props.url} />
      ) : (
        doesPhotoExist && (
          <div
            className={cn(
              "relative w-full flex items-center justify-center overflow-hidden bg-[var(--body-color)] rounded-md",
              simplified && props.type === "snapchat" ? "flex-1" : "h-[300px]"
            )}
          >
            {props.timelapse ? (
              <img
                className="w-full h-full object-contain"
                src={`data:image/gif;base64,${decodeURIComponent(
                  props.timelapse.replace("/media", "")
                )}`}
                alt=""
              />
            ) : props.screenshot ? (
              <img
                className="w-full h-full object-contain"
                src={`data:image/jpeg;base64,${decodeURIComponent(
                  props.screenshot.replace("/media", "")
                )}`}
                alt=""
              />
            ) : isUrlAnImage ? (
              <img
                className="h-full w-full object-contain"
                src={props.photo_url}
                alt=""
              />
            ) : isM3U8 ? (
              <HlsPlayer
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                src={props.photo_url}
                controls
                width="100%"
                height="100%"
              />
            ) : (
              <Video
                onPlay={() => setIsPlaying(true)}
                onPause={() => setIsPlaying(false)}
                photo_url={props.photo_url}
              />
            )}
            {authorDataEnabled && (
              <AuthorDataModal
                className={isPlaying && "translate-y-full"}
                person={
                  Array.isArray(person)
                    ? person.length > 0
                      ? person[0]
                      : null
                    : person
                }
                people={people}
              />
            )}
          </div>
        )
      )}
      {title && props.type !== "twitter" && (
        <h3 className="text-2xl text-font font-medium break-words max-w-full">
          {title}
        </h3>
      )}
      {description &&
        props.type !== "twitter" &&
        (props.type === "ai_satellite" ? (
          <Markdown components={markdownComponents}>{description}</Markdown>
        ) : (
          <p className="text-lg leading-relaxed text-font opacity-80 break-words max-w-full">
            {description}
          </p>
        ))}
      <div className="relative z-10">
        <Attributes
          data={props}
          exclusionsType={exclusionsType}
          excludeKeys={[
            ...(title ? ["title", "name"] : []),
            ...(description ? ["description", "text", "summary"] : []),
            "nearby",
          ]}
        />
        {!simplified && (
          <>
            <div className="flex items-center gap-4 justify-center absolute z-10 h-full w-full inset-0">
              {(workspaceId || workspace_id || href) && (
                <Button asChild>
                  <Link
                    to={
                      href ||
                      `/${workspaceId || workspace_id}/module/${
                        props.type
                      }/item/${props.id}`
                    }
                    className="opacity-0 group-hover:opacity-100 transition-opacity duration-100"
                  >
                    Details
                  </Link>
                </Button>
              )}
              <Button asChild variant="outline" className="text-font">
                <Link
                  to={props.url}
                  className="opacity-0 group-hover:opacity-100 transition-opacity duration-100"
                >
                  Source
                </Link>
              </Button>
              {props.menuChildren && (
                <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-100">
                  {props.menuChildren}
                </div>
              )}
            </div>
            {/* {props.menuChildren && (
                <div className="group-hover:opacity-100 opacity-0 transition-opacity duration-100">
                  {props.menuChildren}
                </div>
              )} */}

            <div className="absolute inset-0 top-6 h-[calc(100%+24px)] w-[calc(100%+48px)] group-hover:opacity-100 opacity-0 backdrop-blur -m-6" />
          </>
        )}
      </div>
      {children}
    </Card>
  );
}

const Video = ({ photo_url, ...props }) => {
  const [isError, setIsError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  return isLoaded && isError ? (
    <img
      src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
      alt="Empty"
    />
  ) : (
    <video
      className="video-js vjs-default-skin object-contain h-full relative w-full min-w-full z-10"
      height="100%"
      width="100%"
      controls
      src={photo_url}
      onLoadedData={(e) => e.currentTarget.src}
      onLoad={() => setIsLoaded(true)}
      onError={() => {
        setIsLoaded(true);
        setIsError(true);
      }}
      {...props}
    ></video>
  );
};

const AttributeField = (props) => (
  <div className="flex flex-col gap-1">
    <p className="text-sm opacity-80">{props.name}</p>
    <h5 className="text-base break-words text-ellipsis limit-text">
      {String(props.value)}
    </h5>
  </div>
);

const AuthorDataModal = ({ person, people, className }) => {
  const [currentAuthorIndex, setCurrentAuthorIndex] = useState(0);
  const hasAnyData = (people && people.length > 0) || !!person;
  if (!hasAnyData) return;
  const isSinglePerson = person && (!people || people?.length === 0);
  const isMultiplePeople = people && people?.length > 0 && !person;
  const allAuthors = hasAnyData
    ? isSinglePerson
      ? [person]
      : isMultiplePeople
      ? people
      : [...people, person]
    : [];

  const currentAuthor = allAuthors[currentAuthorIndex];

  return (
    <div
      className={cn(
        "transition-transform absolute w-full bottom-0 right-0 left-0 max-w-[95%] mx-auto z-10",
        className
      )}
    >
      <div className="absolute right-4 -top-4 flex items-center gap-2 bg-[var(--sidebar-color)] z-20">
        <button
          className="text-[var(--primary-color)] disabled:opacity-60 hover:bg-[var(--primary-color)] hover:text-white disabled:hover:bg-transparent disabled:hover:text-[var(--primary-color)] transition-colors px-1"
          disabled={currentAuthorIndex === 0}
          onClick={() => setCurrentAuthorIndex((prev) => prev - 1)}
        >
          {"<"}
        </button>
        <span className="text-[var(--text-color)] text-sm select-none">
          {currentAuthorIndex + 1} <span className="opacity-80">of</span>{" "}
          {allAuthors.length}
        </span>
        <button
          className="text-[var(--primary-color)] disabled:opacity-60 hover:bg-[var(--primary-color)] hover:text-white disabled:hover:bg-transparent disabled:hover:text-[var(--primary-color)] transition-colors px-1"
          disabled={currentAuthorIndex + 1 >= allAuthors.length}
          onClick={() => setCurrentAuthorIndex((prev) => prev + 1)}
        >
          {">"}
        </button>
      </div>
      <div className="grid grid-cols-2 gap-2 relative z-10 p-4 text-[var(--text-color)]">
        <div>
          <div className="flex items-center gap-3">
            {currentAuthor["photo_url"] && (
              <div className="h-10 w-10 relative rounded-full overflow-hidden">
                <img
                  className="w-full h-full"
                  src={currentAuthor["photo_url"]}
                  alt=""
                />
              </div>
            )}
          </div>
        </div>
        {Object.keys(currentAuthor)
          .filter(
            (key) => !AUTHOR_EXCLUSIONS.includes(key) && currentAuthor[key]
          )
          .slice(0, 3)
          .map((key) => (
            <AttributeField
              colSpan={FIELD_COL_SPAN.includes(key) ? "1 / -1" : ""}
              name={key}
              value={currentAuthor[key]}
              key={key + currentAuthor[key]}
            />
          ))}
      </div>
      <div className="absolute inset-0 h-full w-full opacity-90 bg-[var(--sidebar-color)] backdrop-blur-sm" />
      <div className="absolute w-full h-[2px] bottom-0 bg-[var(--primary-color)] left-0 right-0 z-30 opacity-60"></div>
    </div>
  );
};
