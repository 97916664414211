import { SearchIcon, Trash2 } from "lucide-react";
import { Button } from "../ui/button";
import { Card, CardFooter, CardHeader, CardTitle } from "../ui/card";
import { Link } from "react-router-dom";
import { ICON_TYPES } from "@/const/search";
import { useState } from "react";
import axios from "axios";
import useHashtags from "@/lib/hashtag-search/get-hashtags";
import { cn } from "@/utils/cn";

export default function HashtagRef({ id, platform, name }: Hashtag) {
  const [isLoading, setIsLoading] = useState(false);
  const { mutate } = useHashtags();

  async function handleDelete() {
    setIsLoading(true);
    try {
      await axios.get(`/deleteHashtag/${id}`);
      mutate();
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  }

  return (
    <Card className="p-0 gap-2">
      {platform.length > 0 && (
        <CardHeader className="flex-row space-y-0 items-center gap-2 pb-0">
          {platform.map((p) => (
            <img
              className="h-4 w-4 object-contain"
              src={ICON_TYPES[p.name as keyof typeof ICON_TYPES]}
              key={id + p.name}
              alt=""
            />
          ))}
        </CardHeader>
      )}
      <CardFooter
        className={cn(
          "px-6 pb-6 flex-row justify-between space-y-0 items-center",
          platform.length > 0 ? "pt-0" : "pt-6"
        )}
      >
        <CardTitle>{name}</CardTitle>
        <div className="flex gap-3">
          <Button
            disabled={isLoading}
            type="button"
            variant="outline"
            onClick={handleDelete}
            className="h-9 w-9"
            size="icon"
          >
            <Trash2 size={16} />
          </Button>
          <Button size="sm" className="h-9" asChild>
            <Link to={`/hashtag-search/${id}`}>
              <SearchIcon size={16} />
              Search
            </Link>
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
