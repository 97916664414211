import { useParams } from "react-router-dom";
import MapsProvider from "../../providers/MapsProvider";
import HashtagSearch from "./hashtag-search";
import CoordinatesItems from "./page";

export const hashtagPages = ["tiktok", "facebook"];

export default function TileItems() {
  const { type } = useParams();
  return (
    <MapsProvider>
      {hashtagPages.includes(type!) ? <HashtagSearch /> : <CoordinatesItems />}
    </MapsProvider>
  );
}
