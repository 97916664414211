export default function AiPlaceIcon() {
  return (
<svg fill="inherit" xmlns="http://www.w3.org/2000/svg"
	 viewBox="0 0 512 512" strokeWidth="2" stroke="inherit" x="0px" y="0px"
	 width="120%" height="100%">
	 >

<path className="st0" d="M481,256.4C360.3,263.7,263.7,360.3,256.4,481h-0.9C248.3,360.3,151.7,263.7,31,256.4v-0.9
	C151.7,248.3,248.3,151.7,255.6,31h0.9c7.3,120.7,103.9,217.3,224.6,224.6V256.4z"/>
    </svg>
);
}


