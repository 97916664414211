import Toast from "@/components/toast";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { OSM_FIELDS } from "@/const/search";
import useFilters from "@/providers/search/filters";
import { ChangeEvent, useMemo, useState } from "react";
import toast from "react-hot-toast";
import ShowAll from "./show-all";
import { Search } from "lucide-react";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { cn } from "@/utils/cn";
import { useParams } from "react-router-dom";

export default function OSM({
  place,
  mapBounds,
}: Omit<MenuProps, "isMenuOpen" | "toggleMenu">) {
  const { processSearch, loadingRequests } = useFilters();
  const [distance, setDistance] = useState<number | null>(null);
  const [activeFields, setActiveFields] = useState<string[]>([]);
  const [input, setInput] = useState("");
  const { workspace_id } = useParams();

  const onDistanceChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value === "") return setDistance(0);
    const intValue = parseInt(value);
    if (isNaN(intValue)) {
      return;
    }
    if (intValue > 500 || intValue < 0) return;
    setDistance(intValue);
  };

  const onChoose = (name: string) =>
    setActiveFields((prev) =>
      prev.includes(name)
        ? prev.filter((item) => item !== name)
        : [...prev, name]
    );

  const onSubmit = async () => {
    if (typeof distance !== "number") {
      toast.custom((t) => (
        <Toast isError {...t} title="Distance is required!" />
      ));
      return;
    }
    const body = {
      places: activeFields,
      distance,
      coordinates_id: Number(workspace_id),
      ...place,
      ...mapBounds,
    };
    await processSearch(body, { isOSM: true });
  };

  const validFields = useMemo(() => {
    const initialArr = input
      ? OSM_FIELDS.filter((field) =>
          field.name.toLowerCase().includes(input.toLowerCase())
        )
      : OSM_FIELDS;
    return initialArr.sort((a, b) => {
      //   if (activeFields.includes(a.name) === activeFields.includes(b.name)) {
      return a.name.localeCompare(b.name);
      //   }
      //   return activeFields.includes(a.name) ? -1 : 1;
    });
  }, [input]);

  return (
    <>
      <div className="flex flex-col gap-2 px-6">
        <Label className="text-sm opacity-80" htmlFor="tag">
          Find (#tag) within a distance of
        </Label>
        <div className="relative">
          <Input
            className="text-lg pr-12"
            id="tag"
            placeholder="0-500"
            value={distance?.toString()}
            onChange={onDistanceChange}
          />
          <div className="absolute flex items-center right-0 top-0 bottom-0 px-2 max-w-max">
            <p className="text-sm text-font opacity-80">Meters</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 px-6 pb-2">
        <Label className="text-sm opacity-80" htmlFor="structure">
          Find a structure
        </Label>
        <Input
          className="text-lg pr-12"
          id="structure"
          placeholder="Hospital"
          value={input}
          onChange={(e) => setInput(e.target.value)}
        />
      </div>
      <ScrollArea className="flex-1 max-h-[calc(100vh-384px)]">
        <div className="flex flex-col gap-4 px-6">
          <div className="grid grid-cols-2 gap-x-2 gap-y-4">
            {validFields.map(({ title, name }) => (
              <button
                className={cn(
                  "text-base line-clamp-1 disabled:opacity-60 max-h-min py-2 px-2 rounded-full border whitespace-nowrap",
                  activeFields.includes(name)
                    ? "bg-[var(--primary-color2)] border-[var(--primary-color2)] dark:text-black text-white"
                    : "border-slate-300 dark:border-[#333741] bg-white dark:bg-[#0C111D]"
                )}
                disabled={
                  activeFields.length > 3 && !activeFields.includes(name)
                }
                onClick={() => onChoose(name)}
              >
                {title}
              </button>
            ))}
          </div>
        </div>
      </ScrollArea>
      <div className="grid grid-cols-2 gap-4 px-6">
        <ShowAll />
        <Button
          onClick={onSubmit}
          disabled={loadingRequests.search}
          className="h-9 font-medium"
        >
          <Search size={16} />
          Search
        </Button>
      </div>
    </>
  );
}
