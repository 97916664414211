import { faCheck, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactNode } from "react";
import toastF, { ToastOptions, Toast as ToastProps } from "react-hot-toast";

type Props = {
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  isError?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
};

export function toast(props: Props, options?: ToastOptions) {
  toastF.custom((t) => <Toast {...t} {...props} />, options);
}

export default function Toast({
  title,
  subtitle,
  id,
  visible,
  isError,
  isSuccess,
  isLoading,
}: ToastProps & Props) {
  return (
    <div
      className={`${
        visible ? "animate-enter" : "animate-leave"
      } max-w-md w-full ${
        isError
          ? "bg-red-500"
          : "bg-white dark:bg-[#0a141d] shadow-[0_0_2px_var(--primary-color)]"
      } rounded-md pointer-events-auto flex`}
    >
      <div className="flex-1 w-0 p-4 flex items-center">
        {isLoading ? (
          <FontAwesomeIcon
            className="text-[var(--primary-color)] animate-spin px-2"
            icon={faSpinner}
          />
        ) : (
          isSuccess && (
            <FontAwesomeIcon
              className="text-[var(--primary-color)] px-2"
              icon={faCheck}
            />
          )
        )}
        <div className="flex items-start">
          <div className="ml-3 flex-1">
            <p
              className={`text-sm font-medium ${
                isError ? "text-black" : "text-[#252525] dark:text-[#f5f5f5]"
              }`}
            >
              {title}
            </p>
            {subtitle && (
              <p
                className={`mt-1 text-sm ${
                  isError
                    ? "text-black opacity-80"
                    : "text-[#252525] dark:text-[#f5f5f5] opacity-60"
                } `}
              >
                {subtitle}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => toastF.dismiss(id)}
          className={`w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium ${
            isError ? "text-black" : "text-[var(--primary-color)]"
          } opacity-80 transition-opacity hover:opacity-100`}
        >
          Close
        </button>
      </div>
    </div>
  );
}
