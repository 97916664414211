import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import useSWR from "swr";
import Toast from "../toast";
import { useParams } from "react-router-dom";

type VesselEvent = {
  name: string;
  imo: string;
  eta: string;
  vessel_type: string;
  flag: string;
};

type Response = {
  vessels: VesselEvent[];
};


export default function Ports(props: any) {
  const { workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, isLoading } = useSWR<Response>(
    ["ports", workspace_id, props.id],
    ([_k, workspaceId, id]) =>
      (async (id) => {
        const token = await getAccessTokenSilently();
        const res = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getPortDetails/${workspaceId}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.error) {
          throw new Error(data.message || "Unexpected error");
        }
        return data;
      })(id),
    {
      onError: (err) =>
        toast.custom((t) => (
          <Toast {...t} title={(err as Error).message} isError />
        )),
    }
  );

  return (
    <div className="min-w-64">
      <h4 className="text-font text-lg font-medium mb-3 mt-1 max-w-[90%]">
        <div>Vessels {data && !error && `(${data.vessels.length})`}</div>
      </h4>
       <div>

        {isLoading ? (
          <div className="py-4 flex justify-center">
            <l-waveform size={20} stroke={2} color="var(--text-color)" />
          </div>
        ) : (
          !error && (
              <div className="max-h-[calc(3*40px)] overflow-y-auto scrollbar-hide">
              <table className="w-full">
                <tbody>
                  {data?.vessels.map((vessels, k) => (
                    <EventRef {...vessels} key={`vessels-${k}`} />
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>

    </div>
  );
}

const EventRef = (event: VesselEvent) => (
  <tr className="text-font [&>td]:px-2 [&>td]:py-1 [&:nth-child(even)>td]:bg-white/5 [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r scrollbar-hide">
    <td>{event.name}</td>
    <td>{event.vessel_type}</td>
        <td>
      {event.eta}
    </td>
    <td>{event.flag}</td>
  </tr>
);