/* eslint-disable no-undef */
import { useState, useRef, Fragment, useContext } from "react";
import {
  GoogleMap,
  StandaloneSearchBox,
  Circle,
  InfoWindowF,
} from "@react-google-maps/api";
import CustomMarker from "./CustomMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dark from "./dark.json";
import Bright from "./bright.json";
import { TutorialContext } from "../../providers/tutorial";
import StepPopup from "../tutorial/step";
import {
  faHandPointDown,
  faWind,
  faTemperatureQuarter,
  faCloudRain,
  faMoon,
} from "@fortawesome/free-solid-svg-icons";
import { useTheme } from "@/providers/theme";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { Search } from "lucide-react";
import { cn } from "@/utils/cn";
import useDisasters from "@/lib/add-location/disasters";
import CustomSearchMarker from "@/components/map/CustomMarker";
import DisasterRef from "./disaster";
import { ScrollArea, ScrollBar } from "../ui/scroll-area";
import useMap from "@/hooks/useMap";
import InfoWindowContent from "../tile-items/InfoWindowContent";

const LAYERS = [
  {
    name: "temperature",
    icon: faTemperatureQuarter,
  },
  {
    name: "wind",
    icon: faWind,
  },
  {
    name: "radar",
    icon: faCloudRain,
  },
//   {
//       name: "firms",
//         icon: faCloudRain,
//         }
//   {
//     name: "night",
//     icon: faMoon,
//   },
];

function Map(props) {
  const inputRef = useRef(null);
  const { map, onLoad } = useMap();
  const [searchBox, setSearchBox] = useState(null);
  const [center, setCenter] = useState({
    lat: 37.23806185281545,
    lng: -99.20252111614502,
  });
  const [marker, setMarker] = useState([]);
  const [radius, setRadius] = useState(3000);
  const { isFinished, step, changeStep } = useContext(TutorialContext);
  const { isDark } = useTheme();
  const [selectedDisaster, setSelectedDisaster] = useState(null);
  const { data: disasters } = useDisasters();

  const tutorialFirstVisible = !isFinished && step === 1;
  const tutorialSecondVisible = !isFinished && step === 2;

  const weatherTileUrls = {
    temperature: `https://api.weather.com/v3/TileServer/tile?product=feelsLike&ts={ts}&xyz={x}:{y}:{z}&apiKey=bbd90b15bb534e3c990b15bb53fe3c03`,
    // "rain":"https://api.weather.com/v3/TileServer/tile?product=precip24hr&ts=1737754500&xyz={x}:{y}:{z}&apiKey=bbd90b15bb534e3c990b15bb53fe3c03",
    wind: "https://api.weather.com/v3/TileServer/tile?product=windSpeed&ts={ts}&xyz={x}:{y}:{z}&apiKey=bbd90b15bb534e3c990b15bb53fe3c03",
    radar:
      "https://api.weather.com/v3/TileServer/tile?product=twcRadarMosaic&ts={ts}&xyz={x}:{y}:{z}&apiKey=bbd90b15bb534e3c990b15bb53fe3c03",
//     night:
//       "https://djlorenz.github.io/astronomy/lp2020/overlay/tiles/tile_{z}_{x}_{y}.png",
//     firms: "https://firms.modaps.eosdis.nasa.gov/mapserver/wmts/fires/today/{z}/{x}/{y}",
  };

  const [currentLayer, setCurrentLayer] = useState(null); // Tracks the current layer being displayed
  const [layerVisible, setLayerVisible] = useState(false); // Toggle visibility

  const getRoundedQuarterTimestamp = (time) => {
    const gmtTime = time - new Date().getTimezoneOffset(); // Adjust for GMT
    const minutes = Math.floor((gmtTime % 3600) / 60); // Get minutes of the current hour

    // Find the closest special minute
    const specialMinutes = [5, 20, 35, 50];
    var closestMinute = specialMinutes.reduce((prev, curr) =>
      Math.abs(curr - minutes) < Math.abs(prev - minutes) ? curr : prev
    );
    // find index of closest minute in specialMinutes
    const index = specialMinutes.indexOf(closestMinute);
    if (index === 0) {
      // get last special minute and decrease by 1 hours
      const closestMinute = specialMinutes[specialMinutes.length - 1];
      const hours = Math.floor(gmtTime / 3600) * 3600 - 3600; // Get the hour in seconds
      return hours + closestMinute * 60; // Combine the hour and the closest special minute
    } else {
      const closestMinute = specialMinutes[index - 1];
      const hours = Math.floor(gmtTime / 3600) * 3600; // Get the hour in seconds
      return hours + closestMinute * 60; // Combine the hour and the closest special minute
    }
  };

  const onPlacesChanged = () => {
    const query = searchBox.getPlaces();
    if (!query || query.length === 0) return;
    const newLat = query[0].geometry.location.lat();
    const newLng = query[0].geometry.location.lng();
    setCenter({ lat: newLat, lng: newLng });
    setMarker({
      position: { lat: newLat, lng: newLng },
      circle: {
        options: {
          strokeColor: "#ff00cd",
          fillColor: "#ff00cd",
        },
      },
      id: 1,
      title: query[0].formatted_address,
    });
  };

  const onMapClick = (e) => {
    tutorialFirstVisible && changeStep();
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    setMarker({
      position: {
        lat: lat,
        lng: lng,
      },
      circle: {
        options: {
          strokeColor: "#ff00cd",
          fillColor: "#ff00cd",
        },
      },
      id: 1,
      title: null,
    });
  };

  const onSBLoad = (ref) => {
    setSearchBox(ref);
  };

  const triggerSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const input = inputRef.current;
    input.focus();
    google.maps.event.trigger(input, "keydown", { keyCode: 13 });
  };

  const updateLayer = (layer) => {
    if (!map) return;

    if (layer === currentLayer) {
      setCurrentLayer(null);
      map.overlayMapTypes.clear();
    } else {
     setCurrentLayer(null);
     map.overlayMapTypes.clear();
      const currentTs = Math.floor(Date.now() / 1000);
      const adjustedTs = currentTs - 600;

      const roundedTs = getRoundedQuarterTimestamp(adjustedTs);

      const newLayer = new window.google.maps.ImageMapType({


        getTileUrl: (coord, zoom) => {

          return weatherTileUrls[layer]
            .replace("{z}", zoom)
            .replace("{x}", coord.x)
            .replace("{y}", coord.y)
            .replace("{ts}", roundedTs);
        },
        //             .replace("{fts}", fts);
        tileSize: new window.google.maps.Size(256, 256),
        name: `Weather ${layer}`,
        maxZoom: 7,
        minZoom: 12,
        opacity: 0.4,
      });

      map.overlayMapTypes.insertAt(0, newLayer);
      setCurrentLayer(layer); // Update the state with the current layer
      setLayerVisible(true); // Mark the layer as visible
    }
  };

  // const toggleLayerVisibility = () => {
  //   if (layerVisible) {
  //     if (map) {
  //       map.overlayMapTypes.clear(); // Remove layer
  //     }
  //     setLayerVisible(false); // Set visibility to false
  //   } else {
  //     updateLayer(currentLayer || "temperature"); // Default to temperature layer if none selected
  //   }
  // };

  return (
    <div className="h-full w-full flex flex-col flex-1">
      <div
        className={cn(
          "py-4 px-6 flex gap-4 relative",
          tutorialFirstVisible && "z-20"
        )}
      >
        {props.startContent}
        <div className="flex-1">
          <StandaloneSearchBox
            onPlacesChanged={onPlacesChanged}
            onLoad={onSBLoad}
          >
            <div className="relative">
              <Input
                startContent={<Search size={14} />}
                type="text"
                placeholder="Search for a place..."
                ref={inputRef}
              />
              <Button
                className="absolute right-0 top-0 bottom-0"
                onClick={triggerSearch}
              >
                Search
              </Button>
            </div>
          </StandaloneSearchBox>
        </div>
      </div>
      <div className="flex-1 w-full bg-body z-20 relative">
        {disasters && disasters.length > 0 && (
          <div className="absolute right-0 top-2.5 flex items-start gap-6 z-50 left-56">
            <ScrollArea className="w-full">
              <div className="flex min-w-max space-x-4 pr-6">
                {disasters.map((disaster) => (
                  <DisasterRef
                    {...disaster}
                    onSelect={() => setSelectedDisaster(disaster)}
                    key={disaster.disaster_id}
                  />
                ))}
              </div>
              <ScrollBar orientation="horizontal" />
            </ScrollArea>
          </div>
        )}
        <GoogleMap
          mapContainerStyle={{ height: "100%", width: "100%" }}
          center={center}
          zoom={5}
          onClick={onMapClick}
          options={{
            styles: isDark ? Dark : Bright,
            disableDefaultUI: true,
            mapTypeControl: true,
          }}
          onLoad={onLoad}
        >
          {marker && (
            <Fragment>
              <CustomMarker
                id={marker.id}
                position={marker.position}
                locationTitle={marker.title}
                radius={radius}
                setRadius={setRadius}
                tutorialVisible={tutorialSecondVisible}
                onInfoWindowClose={() => setMarker(null)}
              />
              {marker.circle && (
                <Circle
                  center={{
                    lat: marker.position.lat,
                    lng: marker.position.lng,
                  }}
                  radius={radius}
                  options={marker.circle.options}
                />
              )}
            </Fragment>
          )}
          {disasters &&
            disasters.length > 0 &&
            disasters.map((disaster) => (
              <CustomSearchMarker
                position={disaster.location}
                type={disaster.disaster_type}
                onClick={() => setSelectedDisaster(disaster)}
                key={`disaster-marker-${disaster.disaster_id}`}
              >
                {disaster.disaster_id === selectedDisaster?.disaster_id && (
                  <InfoWindowF
                    position={disaster.location}
                    options={{
                      ariaLabel: "custom-window-marker",
                    }}
                    key={`disaster-infowindow-${disaster.disaster_id}`}
                  >
                    <div className="[&_td]:!col-span-full [&_td:first-child_span:last-child]:!line-clamp-4">
                      <InfoWindowContent
                        obj={disaster}
                        prioritize={["timestamp", "description"]}
                        onlyPriorities
                        key={`disaster-infowindow-content-${disaster.disaster_id}`}
                      />
                    </div>
                  </InfoWindowF>
                )}
              </CustomSearchMarker>
            ))}
        </GoogleMap>
        <div className="absolute bottom-8 right-4 gap-4 z-10 flex flex-col items-center">
          {LAYERS.map(({ name, icon }) => (
            <button
              onClick={() => updateLayer(name)}
              style={{
                backgroundColor: currentLayer === name ? "#ff00cd" : "#293f73",
              }}
              className="p-4 text-white rounded-full text-3xl w-16 h-16"
              key={name}
            >
              <FontAwesomeIcon icon={icon} key={`${name}-icon`} />
            </button>
          ))}
          {/* <button
            onClick={toggleLayerVisibility}
            style={{
              margin: "5px",
              padding: "10px",
              cursor: "pointer",
              backgroundColor: layerVisible ? "red" : "green",
              color: "white",
              border: "none",
              borderRadius: "5px",
            }}
          >
            {layerVisible ? "Hide Layer" : "Show Layer"}
          </button> */}
        </div>
        {tutorialFirstVisible && (
          <StepPopup
            title="Setting a place"
            paragraph="Now you'll need to define a place in the bounds of which an objects are going to be fetched"
            actionText="Click anywhere on the map"
            position="right-0 bottom-0"
            icon={faHandPointDown}
            iconSize="2x"
            iconPosition="bottom-[105%] right-[105%] rotate-[120deg]"
          />
        )}
      </div>
      {(tutorialFirstVisible || tutorialSecondVisible) && (
        <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-10" />
      )}
    </div>
  );
}
export default Map;
