import { Button } from "@/components/ui/button";
import { Plus, SearchIcon } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { FormEvent, useState } from "react";
import { Input } from "@/components/ui/input";
import axios from "axios";
import toast from "react-hot-toast";
import Toast from "@/components/toast";
import useHashtags from "@/lib/hashtag-search/get-hashtags";
import HashtagRef from "@/components/hashtag-search/ref";

export default function HashtagSearch() {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [input, setInput] = useState("");
  const { data: hashtags, error, mutate, isLoading } = useHashtags();

  async function handleSearch(e: FormEvent) {
    e.preventDefault();
    setIsSearching(true);
    const { data } = await axios.post("/addHashtag/", {
      hashtag: input,
    });
    if (data.error) {
      toast.custom(
        (t) => <Toast {...t} isError title="Error" subtitle={data.message} />,
        { duration: Infinity }
      );
    } else {
      mutate();
      setIsDialogOpen(false);
    }
    setIsSearching(false);
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="var(--text-color)" />
      </div>
    );
  }

  if (error)
    return (
      <div className="w-full h-screen flex items-center justify-center text-font">
        Error: {error.message}
      </div>
    );

  return (
    <section className="h-full flex flex-col gap-4 w-full p-8">
      {hashtags && hashtags.length > 0 ? (
        <>
          <div className="flex items-center gap-4 justify-between">
            <h1 className="text-[var(--text-color)] text-3xl font-medium">
              {hashtags.length || 0} Hashtag Search
            </h1>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="outline">
                  <Plus size={16} />
                  Add new
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>Search for posts by hashtag</DialogHeader>
                <form onSubmit={handleSearch}>
                  <div className="py-2 mb-4 space-y-4">
                    <Input
                      type="search"
                      id="add_username"
                      value={input}
                      className="!bg-body dark:!bg-[#101921]"
                      onChange={(event) => setInput(event.target.value)}
                      placeholder={"Enter hashtag"}
                    />
                  </div>
                  <DialogFooter>
                    <Button
                      disabled={isSearching}
                      size="sm"
                      className="dark:bg-blue"
                    >
                      {isSearching ? (
                        <l-waveform
                          color="var(--text-color)"
                          size={16}
                          stroke={1.5}
                        />
                      ) : (
                        <SearchIcon size={16} />
                      )}
                      Search
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col w-full md:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))] gap-8 items-start">
            {hashtags.map((hashtag) => (
              <HashtagRef {...hashtag} key={hashtag.id} />
            ))}
          </div>
        </>
      ) : (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <form
            className="flex flex-col gap-2 w-full max-w-xl"
            onSubmit={handleSearch}
          >
            <h3 className="text-[var(--text-color)] mb-2">
              Search for posts by hashtag
            </h3>
            <Input
              type="search"
              id="add_hashtag"
              value={input}
              onChange={(event) => setInput(event.target.value)}
              className="h-11"
              placeholder={"Enter hashtag"} // Safe fallback to default
            />
            <div className="flex justify-end mt-2">
              <Button disabled={isSearching}>
                {isSearching ? (
                  <l-waveform size={16} stroke={1.5} />
                ) : (
                  <SearchIcon size={16} />
                )}
                Search
              </Button>
            </div>
          </form>
        </div>
      )}
      {/* <LoadingBar height={8} color="#ff00cd" progress={progress} /> */}
    </section>
  );
}
