import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomTooltip from "../components/ui/charts/CustomTooltip";
import {
  ResponsiveContainer,
  YAxis,
  XAxis,
  Tooltip,
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  Legend,
  AreaChart as AreaChartR,
  BarChart,
  Bar,
  Area,
} from "recharts";
import { PIE_CHART_COLORS } from "../const/general";
import Map from "../components/map/Map";
import { HeatmapLayerF } from "@react-google-maps/api";
import useMap from "../hooks/useMap";
import FeaturedBox from "../components/home/featured-box";
import { MODULES } from "@/const/search";
import renderCustomizedLabel from "@/utils/render-customized-label";
import { Card, CardTitle } from "@/components/ui/card";
import AreaChart from "@/components/ui/charts/area";
import { cn } from "@/utils/cn";
import { useParams } from "react-router-dom";
import useArea from "@/lib/search/area";

const tabs: Tab[] = [
  {
    value: "events",
    label: "Events",
  },
  {
    value: "social_media",
    label: "Social Media",
  },
  {
    value: "exposed_devices",
    label: "Exposed Devices",
  },
  {
    value: "transportation",
    label: "Transportation",
  },
  {
    value: "critical_infrastructure",
    label: "Critical infrastructure",
  },
];

export default function Home() {
  const { workspace_id } = useParams();
  const { getAccessTokenSilently, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsSeparated, setEventsSeparated] = useState<
    Record<string, DashboardEvent[]>
  >({});
  const [eventsOverTime, setEventsOverTime] = useState<DashboardEvent[]>([]);
  const [heatmapData, setHeatmapData] = useState<Coords[]>([]);
  const [featured, setFeatured] = useState<SearchObject[][]>([]);
  const [authorDataEnabled] = useState(true);
  const [typeCounter, setTypeCounter] = useState<TypeCount[]>([]);
  const [portsCounter, setPortsCounter] = useState<DashboardEvent[]>([]);
  const [orgCounter, setOrgCounter] = useState<DashboardEvent[]>([]);
  const [searchedCounter, setSearchedCounter] = useState<DashboardEvent[]>([]);
  const [activeTab, setActiveTab] = useState<TabValue>("events");
  const { data: area, isLoading: isAreaLoading } = useArea(workspace_id);
  const { fitBounds } = useMap();

  useEffect(() => {
    setIsLoading(true);
    setEventsOverTime([]);
    setEventsSeparated({});
    setHeatmapData([]);
    setTypeCounter([]);
    (async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getDashboardStatistics2/${workspace_id}/${activeTab}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        if (activeTab === "exposed_devices") {
          setPortsCounter(data.ports_counter);
          setOrgCounter(data.org_counter);
          setSearchedCounter(data.searched_counter);
        } else {
          setEventsOverTime(data.events_over_time);
          setEventsSeparated(data.events_separated);
        }
        setHeatmapData(data.heatmap);
        setTypeCounter(data.type_counter);
        setFeatured([data.featured1, data.featured2, data.featured3]);
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        console.error(err);
      }
    })();
  }, [activeTab, workspace_id, getAccessTokenSilently]);

  useLayoutEffect(() => {
    if (!heatmapData || heatmapData.length === 0) return;
    fitBounds(heatmapData);
  }, [heatmapData, fitBounds]);

  const eventsCount = useMemo(() => {
    const eventTypes = Object.keys(eventsSeparated);
    const combinedEvents: Record<string, CombinedEvent> = {};

    // Flatten and combine the events
    eventTypes.forEach((eventType) => {
      eventsSeparated[eventType].forEach((event) => {
        if (!combinedEvents[event.name]) {
          combinedEvents[event.name] = { date: event.name } as CombinedEvent;
        }
        combinedEvents[event.name][eventType] =
          (combinedEvents[event.name][eventType] || 0) + event.value;
      });
    });

    // Convert the combined events object to an array and sort by date
    const combinedEventsArray = Object.values(combinedEvents);
    combinedEventsArray.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );

    return combinedEventsArray;
  }, [eventsSeparated]);

  return (
    <section className="h-full transition-all flex flex-col px-4 md:pt-8 md:px-8 w-full pb-8">
      <div className="flex items-center gap-4 justify-between flex-wrap">
        {!isAreaLoading && (
          <h1 className="text-[var(--text-color)] font-medium text-3xl sm:text-4xl">
            {area?.title}
          </h1>
        )}
        <h2 className="text-[var(--text-color)] opacity-60 text-xl text-right">
          Welcome, {user?.name}
        </h2>
      </div>
      <div className="py-4 sticky top-0 z-40 bg-[var(--body-color)]">
        <div className="flex items-center rounded-lg bg-[var(--sidebar-color)] overflow-hidden max-w-max border border-slate-300 dark:border-[#333741]">
          {tabs.map((tab) => (
            <button
              onClick={() => setActiveTab(tab.value)}
              className={cn(
                "px-5 py-2.5 font-semibold text-lg flex items-center gap-2.5 border-r border-slate-300 dark:border-[#333741] last:border-r-0",
                activeTab === tab.value
                  ? "text-[var(--primary-color2)] bg-pink/5 dark:bg-[#1F242F]"
                  : "text-[#98A2B3] dark:text-[#CECFD2]"
              )}
              key={tab.value}
            >
              {activeTab === tab.value && (
                <div className="w-2 h-2 rounded-full bg-[var(--primary-color2)]" />
              )}
              {tab.label}
            </button>
          ))}
        </div>
      </div>
      <div className="dashboard flex flex-col gap-6 xl:grid grid-cols-6">
        <Card
          className={
            activeTab === "critical_infrastructure"
              ? "col-span-4"
              : "col-span-2"
          }
        >
          <CardTitle>Heatmap</CardTitle>
          {isLoading ? (
            <div className="w-full flex-1 rounded bg-[var(--body-color)] grid place-content-center">
              <img
                className="animate-spin"
                width={48}
                height={48}
                src="https://cdn.auth0.com/blog/hello-auth0/loader.svg"
                alt=""
              />
            </div>
          ) : heatmapData.length > 0 ? (
            <div className="min-h-[4in] flex-1 relative">
              <Map zoom={10}>
                <HeatmapLayerF
                  options={{
                    gradient: [
                      "rgba(255, 255, 255, 0)",
                      "#ffebf8",
                      "#ffd7f1",
                      "#ffc3ea",
                      "#ffafe3",
                      "#ff9bdc",
                      "#ff87d5",
                      "#ff73ce",
                      "#ff5fc7",
                      "#ff4bc0",
                      "#ff37b9",
                      "#ff23b2",
                      "#ff0fab",
                      "#ff00cd",
                    ],
                  }}
                  data={heatmapData.map(
                    (coords) => new google.maps.LatLng(coords.lat, coords.lng)
                  )}
                />
              </Map>
            </div>
          ) : (
            <div className="flex-1 min-h-[4in] grid place-content-center">
              <p className="text-[var(--text-color)] text-lg">No data found</p>
            </div>
          )}
        </Card>
        {activeTab === "exposed_devices" ? (
          <Card className="col-span-4">
            <CardTitle>Organizations</CardTitle>
            <ResponsiveContainer width="100%" height={420}>
              <BarChart
                data={orgCounter}
                margin={{
                  top: 5,
                  right: 30,
                  left: -20,
                  bottom: 5,
                }}
              >
                <CartesianGrid
                  vertical={false}
                  strokeWidth={1}
                  stroke="#1F242F"
                />
                <XAxis
                  dataKey="name"
                  tickMargin={12}
                  tick={{ fill: "#94969C", fontSize: 14 }}
                  type="category"
                  tickLine={false}
                  axisLine={false}
                />
                <YAxis
                  dataKey="value"
                  tickLine={false}
                  axisLine={false}
                  tick={{ fill: "#94969C", fontSize: 14 }}
                  type="number"
                  tickFormatter={(value) => Math.round(value).toString()}
                />
                <Tooltip
                  cursor={{ fill: "transparent" }}
                  content={<CustomTooltip type="bar" />}
                />
                <Bar
                  type="monotone"
                  dataKey="value"
                  fill="#ff00cd"
                  radius={[24, 24, 0, 0]}
                />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        ) : (
          activeTab !== "critical_infrastructure" && (
            <AreaChart
              title="All events over time"
              data={eventsOverTime}
              height={420}
              className="col-span-4"
            />
          )
        )}
        {activeTab === "exposed_devices" ? (
          <Fragment>
            <Card className="col-span-2">
              <CardTitle>Ports</CardTitle>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  layout="vertical"
                  data={portsCounter}
                  margin={{
                    top: 5,
                    right: 30,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    horizontal={false}
                    strokeWidth={1}
                    stroke="#1F242F"
                  />
                  <XAxis
                    dataKey="value"
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="number"
                    tickFormatter={(value) => Math.round(value).toString()}
                  />
                  <YAxis
                    dataKey="name"
                    tickMargin={12}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="category"
                    tickLine={false}
                    axisLine={false}
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                  <Bar
                    type="monotone"
                    dataKey="value"
                    fill="#00E1FF"
                    radius={[0, 24, 24, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>
            <Card className="col-span-2">
              <CardTitle>Searched</CardTitle>
              <ResponsiveContainer width="100%" height={500}>
                <BarChart
                  data={searchedCounter}
                  margin={{
                    top: 5,
                    right: 30,
                    left: -20,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid
                    vertical={false}
                    strokeWidth={1}
                    stroke="#1F242F"
                  />
                  <XAxis
                    dataKey="name"
                    tickMargin={12}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="category"
                    tickLine={false}
                    axisLine={false}
                  />
                  <YAxis
                    dataKey="value"
                    tickLine={false}
                    axisLine={false}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="number"
                    tickFormatter={(value) => Math.round(value).toString()}
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip />}
                  />
                  <Bar
                    type="monotone"
                    dataKey="value"
                    fill="#293f73"
                    radius={[24, 24, 0, 0]}
                  />
                </BarChart>
              </ResponsiveContainer>
            </Card>
          </Fragment>
        ) : (
          activeTab !== "critical_infrastructure" && (
            <Card className="col-span-4">
              <CardTitle>Events over time by category</CardTitle>
              <ResponsiveContainer width="100%" height={420}>
                <AreaChartR
                  layout="horizontal"
                  data={eventsCount}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 5,
                    bottom: 5,
                  }}
                >
                  <defs>
                    {Object.keys(eventsSeparated).map((type, k) => (
                      <linearGradient
                        id={`${type}-color`}
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="5%"
                          stopColor={
                            MODULES.find((item) => item.value === type)
                              ?.color ||
                            PIE_CHART_COLORS[k % PIE_CHART_COLORS.length]
                          }
                          stopOpacity={0.2}
                        />
                        <stop
                          offset="95%"
                          stopColor={
                            MODULES.find((item) => item.value === type)
                              ?.color ||
                            PIE_CHART_COLORS[k % PIE_CHART_COLORS.length]
                          }
                          stopOpacity={0}
                        />
                      </linearGradient>
                    ))}
                  </defs>
                  <CartesianGrid
                    strokeWidth={1}
                    stroke="#1F242F"
                    vertical={false}
                  />

                  <XAxis
                    axisLine={false}
                    tickLine={false}
                    tickFormatter={(value) =>
                      new Intl.DateTimeFormat("en-US", {
                        month: "short",
                        day: "numeric",
                      }).format(new Date(value))
                    }
                    tickMargin={12}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                    type="category"
                    dataKey="date"
                  />
                  <YAxis
                    tickFormatter={(tick) => Math.round(tick).toString()}
                    axisLine={false}
                    tickLine={false}
                    tickMargin={12}
                    tick={{ fill: "#94969C", fontSize: 14 }}
                  />
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={<CustomTooltip type="multi-line" />}
                  />
                  {Object.keys(eventsSeparated).map((type, k) => (
                    <Area
                      name={type}
                      dot={false}
                      strokeWidth={2}
                      fill={`url(#${type}-color)`}
                      type="monotone"
                      dataKey={type}
                      stroke={
                        MODULES.find((item) => item.value === type)?.color ||
                        PIE_CHART_COLORS[k % PIE_CHART_COLORS.length]
                      }
                    />
                  ))}
                </AreaChartR>
              </ResponsiveContainer>
            </Card>
          )
        )}
        <Card className="col-span-2">
          <CardTitle>Events by category</CardTitle>
          {typeCounter.length > 0 ? (
            <ResponsiveContainer width={"100%"} height={420}>
              <PieChart>
                <Pie
                  data={typeCounter}
                  cx="50%"
                  cy="50%"
                  outerRadius="80%"
                  innerRadius="40%"
                  fill="#8884d8"
                  nameKey="category"
                  dataKey="value"
                  label={(props) => renderCustomizedLabel(props, 24)}
                  labelLine={false}
                  strokeWidth={0}
                >
                  <Legend layout="vertical" verticalAlign="top" align="right" />
                  {typeCounter.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        MODULES.find((item) => item.value === entry.category)
                          ?.color ||
                        PIE_CHART_COLORS[index % PIE_CHART_COLORS.length]
                      }
                    />
                  ))}
                </Pie>
                <Tooltip content={<CustomTooltip name="Count" type="pie" />} />
                <Legend
                  content={({ payload }) =>
                    payload && (
                      <ul className="grid grid-cols-3 gap-1">
                        {payload.map(({ value, color }) => (
                          <li className="text-lg text-[#94969C] flex items-center gap-2">
                            <div
                              style={{ backgroundColor: color }}
                              className="h-2.5 w-2.5 rounded-full"
                            ></div>
                            {value
                              .split("_")
                              .map(
                                (word: string) =>
                                  word.charAt(0).toUpperCase() +
                                  word.substring(1)
                              )
                              .join(" ")}
                          </li>
                        ))}
                      </ul>
                    )
                  }
                ></Legend>
              </PieChart>
            </ResponsiveContainer>
          ) : (
            <div className="flex-1 min-h-[4in] grid place-content-center">
              <p className="text-[var(--text-color)] text-lg">No data found</p>
            </div>
          )}
        </Card>
        {featured.map((_, k, arr) => (
          <FeaturedBox
            index={k}
            authorDataEnabled={false}
            items={featured[k]}
            colSpan={
              arr.every((item) => item.length > 0)
                ? 2
                : arr
                    .filter((_, i) => i !== k)
                    .every((item) => item.length === 0)
                ? 6
                : 4
            }
            key={`featured-${k + 1}`}
          />
        ))}
      </div>
    </section>
  );
}
