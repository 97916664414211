import { Card, CardHeader } from "@/components/ui/card";
import GoogleIcon from "@/const/search/imgs/google.png";
import TiktokIcon from "@/const/search/imgs/tiktok_icon.png";
import TwitterIcon from "@/const/search/imgs/twitter_icon.png";
import useTrending from "@/lib/add-location/trending";
import { ScrollArea } from "../ui/scroll-area";
import { Button } from "../ui/button";
import { Columns2 } from "lucide-react";
import { useState } from "react";
import { cn } from "@/utils/cn";

type Props = {
  isHidden: boolean;
  onHide: () => void;
};

export default function Trending({ isHidden, onHide }: Props) {
  const { data, isLoading, error } = useTrending();

  const googleData = data?.google || [];
  const twitterData = data?.twitter || [];
  const tiktokData = data?.tiktok || [];

  return (
    <aside
      className={cn(
        "bg-body text-white shrink-0 flex flex-col h-full w-96 transition-[max-width] overflow-hidden",
        isHidden ? "max-w-0" : "max-w-96"
      )}
    >
      <div
        className={cn(
          "h-[72px] flex items-center justify-between px-6 border-border",
          !isHidden && "border-r"
        )}
      >
        <h2 className="text-xl font-bold">Trending</h2>
        <Button
          onClick={onHide}
          className="h-8 w-8"
          variant="outline"
          size="icon"
        >
          <Columns2 size={14} />
        </Button>
      </div>
      <div className="flex-1 relative">
        <div className="absolute inset-0 w-full h-full">
          <ScrollArea className="h-full px-6">
            <Card className="mb-6">
              <CardHeader className="p-0">
                <img src={GoogleIcon} alt="Google" className="w-12 h-12" />
              </CardHeader>
              <ol>
                {googleData.map((item, index) => (
                  <li className="py-1 text-base" key={index}>
                   <a href={item.url}> {index + 1}. {item.name}</a>
                  </li>
                ))}
              </ol>
            </Card>
            <Card className="mb-6">
              <CardHeader className="p-0">
                <img src={TwitterIcon} alt="Twitter" className="w-12 h-12" />
              </CardHeader>
              <ol>
                {twitterData.map((item, index) => (
                  <li className="py-1 text-base" key={index}>
                   <a href={item.url}> {index + 1}. {item.name} </a>
                  </li>
                ))}
              </ol>
            </Card>
            <Card className="mb-6">
              <CardHeader className="p-0">
                <img src={TiktokIcon} alt="Tiktok" className="w-12 h-12" />
              </CardHeader>
              <ol>
                {tiktokData.map((item, index) => (

                  <li className="py-1 text-base" key={index}>
                    <a href={item.url}> {index + 1}. {item.name} - {Intl.NumberFormat('en-US').format(Number(item.count))} Views</a>
                  </li>
                ))}
              </ol>
            </Card>
          </ScrollArea>
        </div>
      </div>
    </aside>
  );
}
