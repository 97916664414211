import { ICON_TYPES } from "@/const/search";
import axios from "axios";
import useSWR from "swr";

export type Disaster = {
  title: string;
  disaster_id: string;
  disaster_type: keyof typeof ICON_TYPES;
  severity: string;
  description: string;
  category: string;
  location: Coords;
  timestamp: string;
};

async function getDisasters(): Promise<Disaster[]> {
  return axios.get("/getDisastersAll/").then((res) => res.data.objects);
}

export default function useDisasters() {
  return useSWR(["disasters"], () => getDisasters());
}
