import Trending from "@/components/map_with_search/trending";
import Map from "../components/map_with_search/index";
import Ticker from "@/components/ui/ticker";
import { useState } from "react";
import { Button } from "@/components/ui/button";
import { Columns2 } from "lucide-react";

export default function AddLocation() {
  const [isTrendingHidden, setIsTrendingHidden] = useState(false);
  return (
    <div className="h-full flex flex-col">
      <Ticker />
      <div className="flex flex-1">
        <Trending
          isHidden={isTrendingHidden}
          onHide={() => setIsTrendingHidden(true)}
        />
        <Map
          {...(isTrendingHidden
            ? {
                startContent: (
                  <Button
                    onClick={() => setIsTrendingHidden(false)}
                    className="h-8 w-8 self-center"
                    variant="outline"
                    size="icon"
                  >
                    <Columns2 size={14} />
                  </Button>
                ),
              }
            : {})}
        />
      </div>
    </div>
  );
}
