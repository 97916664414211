import Toast from "@/components/toast";
import useFilters from "@/providers/search/filters";
import { getAllResults } from "@/utils/search/lib";
import { getTaskInfo } from "@/utils/single-item/lib";
import { useAuth0 } from "@auth0/auth0-react";
import { Eye } from "lucide-react";
import { useRef } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;

export default function ShowAll() {
  const { getAccessTokenSilently } = useAuth0();
  const { setLoadingRequests, loadingRequests, setMarkerList } = useFilters();
  const { workspace_id } = useParams();
  const timer = useRef<number | null>(null);

  async function showAll() {
    setLoadingRequests((prev) => ({ ...prev, showAll: true }));
    try {
      const token = await getAccessTokenSilently();
      const  results = await getAllResults(
        token,
        Number(workspace_id)
      );
      if (results.error) {
        setLoadingRequests((prev) => ({ ...prev, showAll: false }));
        toast.custom((t) => (
          <Toast {...t} isError title="Error" subtitle="Please contact support" />
        ));
      } else {
            setMarkerList((results.result.results || []) as Marker[]);
            setLoadingRequests((prev) => ({ ...prev, showAll: false }));
      }
    } catch (error) {
      console.error("There was an error!", error);
      toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Error"
          subtitle={(error as Error).message}
        />
      ));
    }
  }

  return (
    <button
      onClick={showAll}
      disabled={loadingRequests.showAll}
      className="text-sm text-[#94969C] flex items-center gap-2 justify-center rounded-md h-9 disabled:opacity-60"
    >
      {loadingRequests.showAll ? (
        <l-waveform size={14} color="#94969C" stroke={1} />
      ) : (
        <Eye size={14} />
      )}{" "}
      Show All
    </button>
  );
}
