import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import useSWR from "swr";
import Toast from "../toast";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { useParams } from "react-router-dom";

type Response = {
  estimated_foottraffic: number;
  visits: number;
  bins: string;
}[];

export default function Store(props: any) {
  const { workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, isLoading } = useSWR<{ result: Response }>(
    ["store", workspace_id, props.id],
    ([_k, workspaceId, id]) =>
      (async (id) => {
        const token = await getAccessTokenSilently();
        const res = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getStoreDetails/${workspaceId}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.error) {
          throw new Error(data.message || "Unexpected error");
        }
        return data;
      })(id),
    {
      //   onSuccess: (data) => data.live.length > 0 && setActiveTab("live"),
      onError: (err) =>
        toast.custom((t) => (
          <Toast {...t} title={(err as Error).message} isError />
        )),
    }
  );

  return (
    <div className="min-w-96">
      <h4 className="text-font text-lg font-medium mb-3 mt-1 max-w-[90%]">
        {props.store_name}
      </h4>

      {isLoading ? (
        <div className="py-4 flex justify-center">
          <l-waveform size={20} stroke={2} color="var(--text-color)" />
        </div>
      ) : (
        !error && (
          <ResponsiveContainer width="100%" height={160}>
            <AreaChart data={data?.result || []}>
              <Area
                dataKey="visits"
                type="natural"
                stroke="var(--primary-color2)"
                fill="var(--primary-color2)"
                fillOpacity={0.4}
                stackId="a"
              />
              <Area
                dataKey="estimated_foottraffic"
                type="natural"
                stroke="var(--primary-color)"
                fill="var(--primary-color)"
                fillOpacity={0.4}
                stackId="a"
              />
              <YAxis
                tickLine={false}
                axisLine={false}
                width={40}
                tickFormatter={(value) =>
                  new Intl.NumberFormat("en-US", {
                    notation: "compact",
                  }).format(value)
                }
              />
              <CartesianGrid
                stroke="var(--text-color)"
                strokeOpacity={0.2}
                strokeWidth={0.5}
                vertical={false}
              />
              <XAxis
                dataKey="bins"
                tickLine={false}
                interval={0}
                axisLine={false}
                tickFormatter={(value) =>
                  new Intl.DateTimeFormat("en-US", {
                    dateStyle: "short",
                  }).format(new Date(value))
                }
              />
              <Tooltip
                cursor={{ fill: "transparent" }}
                content={({ payload, label }) => {
                  return (
                    <div className="bg-[var(--sidebar-color)] px-4 py-2 rounded border-border border flex flex-col gap-1">
                      <span className="text-base text-font">
                        {label &&
                          new Intl.DateTimeFormat("en-US", {
                            dateStyle: "long",
                          }).format(new Date(label))}
                      </span>
                      {payload &&
                        payload.map((stat) => (
                          <TooltipRecord {...stat} key={stat.name} />
                        ))}
                    </div>
                  );
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        )
      )}
    </div>
  );
}

const TooltipRecord = ({
  name,
  value,
  color,
}: Payload<ValueType, NameType>) => (
  <div className="flex items-center justify-between gap-4">
    <div className="flex items-center gap-2">
      <div className="w-3 h-2 rounded" style={{ backgroundColor: color }}></div>
      <span className="text-font text-base opacity-75">{name}</span>
    </div>
    <span className="text-font text-base">{value}</span>
  </div>
);

// const EventRef = (event: StadiumEvent) => (
//   <tr className="text-font [&>td]:px-2 [&>td]:py-1 [&:nth-child(even)>td]:bg-white/5 [&>td:first-child]:text-left [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r [&>td]:text-center [&>td:last-child]:text-right">
//     <td>{event.home_team}</td>
//     <td className="w-1/2">
//       <p className="text-xl">
//         {event.home_results}:{event.away_results}
//       </p>
//       <p className="text-sm opacity-60">
//         {new Intl.DateTimeFormat("en-US", {
//           dateStyle: "medium",
//           timeStyle: "short",
//         }).format(new Date(event.timestamp))}
//       </p>
//     </td>
//     <td>{event.away_team}</td>
//   </tr>
// );
