import axios from "axios";
import useSWR from "swr";

async function getHashtags(): Promise<Hashtag[]> {
  return new Promise((resolve, reject) =>
    axios
      .get("/getHashtags")
      .then((res) =>
        res.data.error ? reject(res.data.error) : resolve(res.data.objects)
      )
  );
}

export default function useHashtags() {
  return useSWR(["hashtags"], () => getHashtags());
}
