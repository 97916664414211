import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import Toast from "../../components/toast";
import {
  getErrors,
  getProgress,
  getResults,
  postOSMSearch,
  postSearch,
} from "../../utils/search/lib";
import { getTaskInfo } from "../../utils/single-item/lib";
import { POPUP_MODULES } from "@/const/search";

type SearchId = {
  value: string | number | null;
  isOSM: boolean;
};

const defaultSearchId: SearchId = { value: null, isOSM: false };

export default function useSearch(onSuccess?: () => void) {
  const { getAccessTokenSilently } = useAuth0();
  const [fetchedModules, setFetchedModules] = useState<string[]>([]);
  const timer = useRef<number | null>(null);
  const [searchId, setSearchId] = useState<SearchId>(defaultSearchId);
  const [error, setError] = useState<string | null>(null);
  const [weather, setWeather] = useState<any>(null);
  const [news, setNews] = useState<News[]>([]);
  const [newspapers, setNewspapers] = useState<Newspaper[]>([]);
  const [radios, setRadios] = useState<RadioResult[]>([]);
  const [progress, setProgress] = useState({
    completed: 0,
    value: 0,
    retries: 0,
  });
  const [loadingRequests, setLoadingRequests] = useState({
    search: false,
    showAll: false,
  });
  const [markerList, setMarkerList] = useState<Marker[]>([]);

  useEffect(() => {
    // prevent from doing anything while the searchId is null
    if (!searchId.value) return;

    // stop the loop by reseting searchId and additionally - reset progress and fetchedModules for other requests

    if (progress.value >= 100) {
      setFetchedModules([]);
      setSearchId(defaultSearchId);
      setProgress({ completed: 0, value: 0, retries: 0 });
      setLoadingRequests((prev) => ({ ...prev, search: false }));
      return;
    }

    timer.current = window.setTimeout(fetchProgress, 1000);
    async function fetchProgress() {
      try {
        switch (searchId.isOSM) {
          case true:
            await processOSMResults(searchId.value as string);
            break;
          case false:
            await processRegularResults(searchId.value as string);
            break;
        }
      } catch (err) {
        const error = err as Error;
        if (error.name !== "AbortError") {
          // Ignore fetch cancellations
          setError(error.message);
        }
      }
    }
    return () => {
      // prevent multiple timer instances
      timer.current && window.clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAccessTokenSilently, searchId.value, progress]);

  async function processRegularResults(searchId: string) {
    const token = await getAccessTokenSilently();
    const newProgress = await getProgress(token, searchId);

    // check for results only if the number of currently completed modules is greater than those completed previously

    let results: Marker[] = [];
    if (newProgress.completed > progress.completed)
      results = await getResults(token, searchId);

    // if there are some results, filter for those which have not been displayed yet and toast each of them

    if (results.length > 0) {
      const newResults = results.filter(
        (result) =>
          !fetchedModules.includes(result.type) && result.type !== "weather"
      );
      const weatherResult = results.find((item) => item.type === "weather");
      const newsResult = results.find((item) => item.type === "news");
      const radioResult = results.find((item) => item.type === "radio");
      const newspapersResult = results.find(
        (item) => item.type === "newspaper"
      );
      weatherResult && setWeather(weatherResult.objects[0]);
      newsResult && setNews(newsResult.objects as any);
      radioResult && setRadios(radioResult.objects as RadioResult[]);
      newspapersResult &&
        setNewspapers(newspapersResult.objects as Newspaper[]);
      newResults.forEach(({ type, objects }) => {
        objects.length > 0 &&
          toast.custom(
            (t) => (
              <Toast
                {...t}
                title={
                  <span>
                    Searching for the{" "}
                    <span className="text-[var(--primary-color)] font-medium">
                      {type}
                    </span>{" "}
                    module complete!
                  </span>
                }
                subtitle={
                  (
                    <span>
                      Found{" "}
                      <span className="text-[var(--primary-color)] font-medium">
                        {objects.length}
                      </span>{" "}
                      objects for the{" "}
                      <span className="text-[var(--primary-color)] font-medium">
                        {type}
                      </span>{" "}
                      module.
                    </span>
                  ) as any
                }
              />
            )
            // { duration: 200 }
          );
      });
      // set state based on the filtered results and mark their modules as previously fetched

      setMarkerList((prev) => [
        ...prev,
        ...newResults.filter(
          (item) =>
            !POPUP_MODULES.includes(item.type as (typeof POPUP_MODULES)[number])
        ),
      ]);
      setFetchedModules((prev) => [
        ...prev,
        ...newResults.map((item) => item.type),
      ]);
      onSuccess && onSuccess();
    }

    // check for errors: if some do exist - toast each failed module, otherwise - 404 in case of 0 results

    if (newProgress.progress >= 100) {
      const errors: string[] = await getErrors(token, searchId);
      const noResultsModules = results
        .filter(
          (item) =>
            item.objects.length === 0 &&
            !errors.map((item) => item.toLowerCase()).includes(item.type)
        )
        .map((item) => item.type);
      noResultsModules.length > 0 &&
        toast.custom((t) => (
          <Toast
            {...t}
            title="No results"
            subtitle={
              (
                <span>
                  Nothing has been found for{" "}
                  <span className="text-[var(--primary-color)] font-medium">
                    {noResultsModules.join(", ")}
                  </span>{" "}
                  modules
                </span>
              ) as any
            }
          />
        ));
      errors.length > 0 &&
        toast.custom(
          (t) => (
            <Toast
              {...t}
              isError
              title="Errors"
              subtitle={
                (
                  <span>
                    Error with{" "}
                    <span className="text-[var(--primary-color)] font-medium">
                      {errors.join(", ")}
                    </span>{" "}
                    module(s). Please try again. No credits have been deducted.
                  </span>
                ) as any
              }
            />
          ),
          { duration: Infinity }
        );
    }

    // set new progress, so that the request loop continues

    setProgress((prev) => ({
      value: newProgress.progress,
      completed: newProgress.completed,
      total: newProgress.total,
      retries: prev.retries + 1,
    }));
  }

  async function processOSMResults(searchId: string) {
    const { results, error } = await getTaskInfo<OSMResult[]>(searchId);
    if (!results[0] || error) {
      setProgress({ completed: 0, value: 0, retries: 0 });
      toast.custom((t) => (
        <Toast
          {...t}
          title="Something went wrong!"
          subtitle={error || "No results have been found"}
        />
      ));
      return;
    }
    const { state, result } = results[0];
    if (result) {
      const { percent, total, current, message, results: newResults } = result;

      if (!Array.isArray(newResults) || newResults.length === 0) {
        toast.custom((t) => (
          <Toast {...t} title="No results" subtitle={message || "Error"} />
        ));
      } else {
        setMarkerList((prev) => [
          ...prev,
          { type: "osm", objects: newResults },
        ]);
      }
      setProgress((prev) => ({
        value: percent,
        completed: current,
        total,
        retries: prev.retries + 1,
      }));
      return;
    }
    if (state === "PENDING") {
      setProgress((prev) => ({
        ...prev,
        retries: prev.retries + 1,
      }));
      return;
    } else {
      setProgress((prev) => ({
        value: 100,
        completed: 100,
        total: 100,
        retries: prev.retries + 1,
      }));
      toast.custom((t) => (
        <Toast
          {...t}
          title="Something went wrong!"
          subtitle={"No results have been found"}
        />
      ));
    }
  }

  async function processSearch(body: any, options?: { isOSM?: boolean }) {
    setLoadingRequests((prev) => ({ ...prev, search: true }));
    const token = await getAccessTokenSilently();
    const data = await (options?.isOSM
      ? postOSMSearch(token, body)
      : postSearch(token, body));
    switch (data.status) {
      case 400: {
        setLoadingRequests((prev) => ({ ...prev, search: false }));
        data.message &&
          toast.custom((t) => (
            <Toast {...t} isError title="Error" subtitle={data.message} />
          ));
        break;
      }
      case 201:
      case 200: {
        if (options?.isOSM) {
          data["task_id"]
            ? setSearchId({ value: data["task_id"], isOSM: true })
            : setLoadingRequests((prev) => ({ ...prev, search: false }));
        } else {
          data["search_pk"]
            ? setSearchId({ value: data["search_pk"], isOSM: false })
            : setLoadingRequests((prev) => ({ ...prev, search: false }));
          const blockedModules = data["blocked_modules"];
          if (blockedModules.length > 0) {
            toast.custom((t) => (
              <Toast
                {...t}
                title="Limit Reached"
                subtitle={
                  (
                    <span>
                      Limit for modules{" "}
                      <span className="text-[var(--primary-color)] font-medium">
                        {blockedModules.join(", ")}
                      </span>{" "}
                      has been reached. Please upgrade your plan to continue
                      using this module.
                    </span>
                  ) as any
                }
              />
            ));
          }
        }
        break;
      }
      default:
        return;
    }
  }

  return {
    progress,
    searchId,
    setSearchId,
    error,
    newspapers,
    loadingRequests,
    setLoadingRequests,
    news,
    radios,
    fetchedModules,
    weather,
    markerList,
    setMarkerList,
    setWeather,
    setNews,
    processSearch,
  };
}
