import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import useSWR from "swr";
import Toast from "../toast";
import { useParams } from "react-router-dom";

type AirportEvent = {
  callsign: string;
  city: string;
  country: string;
  live_status: string;
  timestamp: string;
};

type Response = {
  arrivals: AirportEvent[];
  departures: AirportEvent[];
};

export default function Airport(props: any) {
  const { workspace_id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, isLoading } = useSWR<Response>(
    ["airport", workspace_id, props.id],
    ([_k, workspaceId, id]) =>
      (async (id) => {
        const token = await getAccessTokenSilently();
        const res = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getAirportDetails/${workspaceId}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.error) {
          throw new Error(data.message || "Unexpected error");
        }
        return data;
      })(id),
    {
      onError: (err) =>
        toast.custom((t) => (
          <Toast {...t} title={(err as Error).message} isError />
        )),
    }
  );

  return (
    <div className="min-w-64">
      <h4 className="text-font text-lg font-medium mb-3 mt-1 max-w-[90%]">
        {props.name}
      </h4>
      <Tabs defaultValue="arrivals">
        <TabsList className="w-full grid grid-cols-2">
          <TabsTrigger value="arrivals">
            Arrivals {data && !error && `(${data.arrivals.length})`}
          </TabsTrigger>
          <TabsTrigger value="departures">
            Departures {data && !error && `(${data.departures.length})`}
          </TabsTrigger>
        </TabsList>
        {isLoading ? (
          <div className="py-4 flex justify-center">
            <l-waveform size={20} stroke={2} color="var(--text-color)" />
          </div>
        ) : (
          !error && (
            <>
              <TabsContent value="arrivals">
                <table className="w-full">
                  <tbody>
                    {data?.arrivals.map((arrival, k) => (
                      <EventRef {...arrival} key={`arrival-${k}`} />
                    ))}
                  </tbody>
                </table>
              </TabsContent>
              <TabsContent value="departures">
                <table className="w-full">
                  <tbody>
                    {data?.departures.map((departure, k) => (
                      <EventRef {...departure} key={`departure-${k}`} />
                    ))}
                  </tbody>
                </table>
              </TabsContent>
            </>
          )
        )}
      </Tabs>
    </div>
  );
}

const EventRef = (event: AirportEvent) => (
  <tr className="text-font [&>td]:px-2 [&>td]:py-1 [&:nth-child(even)>td]:bg-white/5 [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r">
    <td>{event.callsign}</td>
    <td>
      {event.city}
      {event.country ? `, ${event.country}` : ""}
    </td>
    <td>{event.live_status}</td>
  </tr>
);
