import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import useSWR from "swr";
import Toast from "../toast";
import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../ui/tabs";
import { useParams } from "react-router-dom";

type StadiumEvent = {
  timestamp: string;
  home_team: string;
  home_results: string;
  away_team: string;
  away_results: string;
  status: "played";
};

type Response = {
  played: StadiumEvent[];
  upcoming: StadiumEvent[];
  live: StadiumEvent[];
};

export default function Stadium(props: any) {
  const { workspace_id } = useParams();
  const [activeTab, setActiveTab] = useState<"played" | "live" | "upcoming">(
    "played"
  );
  const { getAccessTokenSilently } = useAuth0();
  const { data, error, isLoading } = useSWR<Response>(
    ["stadium", workspace_id, props.id],
    ([_k, workspaceId, id]) =>
      (async (id) => {
        const token = await getAccessTokenSilently();
        const res = await fetch(
          `${process.env.REACT_APP_API_SERVER_URL}/api/getStadiumDetails/${workspaceId}/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.error) {
          throw new Error(data.message || "Unexpected error");
        }
        return data;
      })(id),
    {
      onSuccess: (data) => data.live.length > 0 && setActiveTab("live"),
      onError: (err) =>
        toast.custom((t) => (
          <Toast {...t} title={(err as Error).message} isError />
        )),
    }
  );

  console.log(data);

  return (
    <div className="min-w-64">
      <h4 className="text-font text-lg font-medium mb-3 mt-1 max-w-[90%]">
        {props.name}
      </h4>
      <Tabs
        value={activeTab}
        onValueChange={(value) =>
          setActiveTab(value as "played" | "live" | "upcoming")
        }
      >
        <TabsList className="w-full grid grid-cols-3">
          <TabsTrigger value="played">
            Played {data && !error && `(${data.played.length})`}
          </TabsTrigger>
          <TabsTrigger value="live">
            Live{" "}
            {data && !error && data.live.length > 0 && (
              <div className="h-2 w-2 rounded-full bg-red-600" />
            )}
          </TabsTrigger>
          <TabsTrigger value="upcoming">
            Upcoming {data && !error && `(${data.upcoming.length})`}
          </TabsTrigger>
        </TabsList>
        {isLoading ? (
          <div className="py-4 flex justify-center">
            <l-waveform size={20} stroke={2} color="var(--text-color)" />
          </div>
        ) : (
          !error && (
            <>
              <TabsContent value="played">
                <table className="w-full table-fixed max-w-sm">
                  <tbody>
                    {data?.played.map((match, k) => (
                      <EventRef {...match} key={`stadium-played-${k}`} />
                    ))}
                  </tbody>
                </table>
              </TabsContent>
              <TabsContent value="live">
                <table className="w-full table-fixed max-w-sm">
                  <tbody>
                    {data?.live.map((match, k) => (
                      <EventRef {...match} key={`stadium-live-${k}`} />
                    ))}
                  </tbody>
                </table>
              </TabsContent>
              <TabsContent value="upcoming">
                <table className="w-full table-fixed max-w-sm">
                  <tbody>
                    {data?.upcoming.map((match, k) => (
                      <EventRef {...match} key={`stadium-upcoming-${k}`} />
                    ))}
                  </tbody>
                </table>
              </TabsContent>
            </>
          )
        )}
      </Tabs>
    </div>
  );
}

const EventRef = (event: StadiumEvent) => (
  <tr className="text-font [&>td]:px-2 [&>td]:py-1 [&:nth-child(even)>td]:bg-white/5 [&>td:first-child]:text-left [&>td:first-child]:rounded-l [&>td:last-child]:rounded-r [&>td]:text-center [&>td:last-child]:text-right">
    <td>{event.home_team}</td>
    <td className="w-1/2">
      <p className="text-xl">
        {event.home_results}:{event.away_results}
      </p>
      <p className="text-sm opacity-60">
        {new Intl.DateTimeFormat("en-US", {
          dateStyle: "medium",
          timeStyle: "short",
        }).format(new Date(event.timestamp))}
      </p>
    </td>
    <td>{event.away_team}</td>
  </tr>
);
