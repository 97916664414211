import ObjectRef from "@/components/tile-items/ObjectRef";
import Toast from "@/components/toast";
import {
  Card,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { ICON_TYPES } from "@/const/search";
import useHashtagPosts, {
  HASHTAG_MODULES,
} from "@/lib/hashtag-search/get-hashtag-posts";
import processTask from "@/lib/task";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

export default function HashtagPage() {
  const [isSearching, setIsSearching] = useState<
    (typeof HASHTAG_MODULES)[number] | null
  >(null);
  const { id: hashtag_id } = useParams() as Readonly<{ id: string }>;
  const posts = useHashtagPosts(hashtag_id);
  const [progress, setProgress] = useState(0);
  const [expandedModules, setExpandedModules] = useState<
    (typeof HASHTAG_MODULES)[number][]
  >(["tiktok", "twitter", "instagram"]);
  const listRefs = useRef<HTMLDivElement[]>([]);

  const handleSearch = async (name: (typeof HASHTAG_MODULES)[number]) => {
    if (expandedModules.includes(name)) {
      console.log("searching", name);
      setIsSearching(name);
      setProgress(20);
      const results = await axios
        .post("/searchHashtag/", {
          hashtag_id,
          platforms: [name],
        })

      if (results.data.data) {
        // if (!taskInfo.data || taskInfo.data.length === 0) return;
        // posts[name].mutate((prev) => {
        //   if (!prev) return prev;
        //   const newArr = [...prev];
        //   newArr[0].data = [...taskInfo.posts!, ...newArr[0].data];
        //   return newArr;
        // });
        posts[name].mutate();
        setProgress(100);
        setIsSearching(null);
        toast.custom((t) => (
          <Toast
            {...t}
            isSuccess
            title="Search succeeded!"
            subtitle={`${results.data.data.length || 0} post(s) found for ${name}`}
          />
        ));
      }}
     else {
      window.scrollTo({ top: 0 });
      setExpandedModules((prev) => [name, ...prev.slice(0, 2)]);
    }
  }


  useEffect(() => {
    const onScroll = (_e: Event) => {
      const lists = listRefs.current.filter((list) => {
        const { data, isValidating } = posts[list.id as keyof typeof posts];
        if (isValidating || !data || data.length === 0) return false;
        const lastPage = data[data.length - 1];
        if (lastPage.current_page >= lastPage.total_pages) return false;
        const { offsetTop, offsetHeight } = list;
        return window.scrollY >= offsetTop + offsetHeight - window.innerHeight;
      });
      // lists.forEach((list) => console.log(list.id));
      lists.forEach((list) =>
        posts[list.id as keyof typeof posts].setSize((prev) => prev + 1)
      );
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(posts).map((m) => m.isValidating)]);

  // useEffect(() => {
  //   // @ts-ignore
  //   console.log({ twttr: window.twttr });
  //   if (!posts.twitter.data?.length) return;
  //   // @ts-ignore
  //   window.twttr?.widgets.load();
  // }, [posts.twitter.data?.length]);

  return (
    <div className="h-full flex flex-col p-8">
      <h1 className="text-font text-xl font-medium">Hashtag Search</h1>
      {posts &&
        Object.values(posts).some((m) => m.data && m.data.length > 0) && (
          <strong className="text-3xl font-bold text-font">
            {
              Object.values(posts).find((m) => !!m.data && m.data.length > 0)!
                .data![0].hashtag
            }
          </strong>
        )}
      <div className="py-6 sticky top-0 z-40 bg-body">
        <div
          style={{
            gridTemplateColumns: HASHTAG_MODULES.map((mod) =>
              expandedModules.includes(mod) ? "minmax(0, 1fr)" : "72px"
            ).join(" "),
          }}
          className="grid gap-6"
        >
          {HASHTAG_MODULES.map((name) => (
            <ModuleRef
              name={name}
              isDisabled={!!isSearching}
              onSearch={() => handleSearch(name)}
              isLoading={isSearching === name}
              key={name}
            />
          ))}
        </div>
      </div>
      <div
        style={{
          gridTemplateColumns: HASHTAG_MODULES.map((mod) =>
            expandedModules.includes(mod) ? "minmax(0, 1fr)" : "72px"
          ).join(" "),
        }}
        className="grid items-start gap-6"
      >
        {HASHTAG_MODULES.map((name, index) =>
          expandedModules.includes(name) ? (
            <div
              className="flex flex-col gap-6 posts-list"
              id={name}
              ref={(ref) => ref && (listRefs.current[index] = ref)}
              key={`${name}-list`}
            >
              {posts[name].data &&
              posts[name].data.length > 0 &&
              posts[name].data.some((page) => page.data.length > 0)
                ? posts[name].data.flatMap((posts) =>
                    posts.data.map((record) => (
                      <ObjectRef
                        {...record}
                        showTypeIcon
                        href={`/hashtag-search/${hashtag_id}/${record.type}/${record.id}`}
                        authorDataEnabled
                        key={record.id}
                      />
                    ))
                  )
                : isSearching !== name &&
                  !posts[name].isValidating && (
                    <Card>
                      <CardDescription className="text-center">
                        No posts found
                      </CardDescription>
                    </Card>
                  )}
              {posts[name].isValidating && (
                <div
                  className="max-w-max self-center my-4"
                  key={`${name}-loader-wrapper`}
                >
                  <l-waveform size={24} stroke={2} color="var(--text-color)" />
                </div>
              )}
            </div>
          ) : (
            <div className="min-w-[72px]" key={`${name}-empty`} />
          )
        )}
      </div>
      <LoadingBar height={8} color="#ff00cd" progress={progress} />
    </div>
  );
}

interface ModuleRefProps {
  name: (typeof HASHTAG_MODULES)[number];
  onSearch: () => void;
  isDisabled: boolean;
  isLoading: boolean;
}

const ModuleRef = ({
  name,
  isDisabled,
  isLoading,
  onSearch,
}: ModuleRefProps) => {
  return (
    <button
      className="disabled:opacity-60 w-full block"
      disabled={isDisabled}
      onClick={onSearch}
    >
      <Card className="p-0 overflow-hidden">
        <CardHeader className="flex-row space-y-0 gap-4 items-center justify-between">
          <div className="flex items-center gap-6">
            <img
              className="w-6 h-6 object-contain"
              src={ICON_TYPES[name as keyof typeof ICON_TYPES]}
              alt=""
            />
            <CardTitle>
              {name.charAt(0).toUpperCase() + name.substring(1)}
            </CardTitle>
          </div>
          {isLoading ? (
            <l-waveform size={20} stroke={1.5} color="var(--text-color)" />
          ) : (
            <FontAwesomeIcon icon={faArrowsRotate} />
          )}
        </CardHeader>
      </Card>
    </button>
  );
};
