import axios from "axios";
import useSWR, { SWRConfiguration } from "swr";

async function getHashtagItem(
  id: string,
  module_name: string,
  hashtag_id: string
) {
  const { data } = await axios.get(
    `/getHashtag/${hashtag_id}/${module_name}/${id}`
  );
  return data[0];
}

export default function useHashtagItem(
  id: string,
  moduleName: string,
  hashtagId: string,
  config?: SWRConfiguration<SingleRecord>
) {
  return useSWR(
    hashtagId ? ["hashtag-item", id, moduleName, hashtagId] : null,
    ([_, item_id, module_name, hashtag_id]) =>
      getHashtagItem(item_id, module_name, hashtag_id),
    config
  );
}
