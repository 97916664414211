import CustomMarker from "@/components/map/CustomMarker";
import Map from "@/components/map/Map";
import Pagination from "@/components/pagination";
import AuthorRef from "@/components/single-item/author-ref";
import ObjectRef from "@/components/tile-items/ObjectRef";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import ArrowRightIcon from "@/const/search/imgs/arrow-right";
import usePersonInvestigated from "@/lib/single-item/person-investigation";
import { cn } from "@/utils/cn";
import { Button } from "../components/ui/button";
import InfoWindowContent from "../components/tile-items/InfoWindowContent";
import {
  DirectionsRenderer,
  InfoWindowF,
  MarkerClustererF,
} from "@react-google-maps/api";
import { clusterIcons } from "../const/search";
import useMap from "@/hooks/useMap";
// import axios from "axios";
// import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { getTaskInfo } from "@/utils/single-item/lib";
import { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import Toast from "@/components/toast";
import { SearchIcon } from "lucide-react";
import LoadingBar from "react-top-loading-bar";
import PersonActivity from "@/components/people-investigated/activity";
import renderDirections from "@/utils/render-directions";

export default function PersonInvestigation() {
  const [isSearching, setIsSearching] = useState(false);
  const { person_id } = useParams() as Readonly<PersonInvestigationParams>;
  const { map, fitBounds } = useMap();
  const {
    data: person,
    isLoading,
    error,
    isValidating,
    mutate,
  } = usePersonInvestigated(person_id);
  const [progress, setProgress] = useState(0);
  const [page, setPage] = useState(1);
  const [directions, setDirections] =
    useState<google.maps.DirectionsResult | null>(null);
  const [infoWindow, setInfoWindow] = useState<number | null>(null);
  // const isPolygon = POLYGON_MODULES.find((item) => item.value === type);
  const mapContainer = useRef<HTMLDivElement | null>(null);

  async function handleSearch() {
    if (isSearching) return;
    setIsSearching(true);
    setProgress(5);
    try {
      const { data } = await axios.post("/getGeotaggedPosts/", {
        person_id,
        modules: [person?.type],
      });
      if (data.error) {
        toast.custom(
          (t) => <Toast {...t} isError title="Error" subtitle={data.message} />,
          { duration: Infinity }
        );
        setIsSearching(false);
        setProgress(100);
        return;
      }
      if (!data.error) {
              toast.custom(
                (t) => (
                  <Toast
                    {...t}
                    title="Success"
                    subtitle={`${data.posts.length} post(s) found`}
                  />
                ),
                { duration: Infinity }
              );
              mutate();
              setProgress(100);
              setIsSearching(false);
            }
    } catch (error) {
      console.error("There was an error!", error);
      setIsSearching(false);
      setProgress(100);
    }
  }

  // const doesPhotoExist =
  //   !!person?.associated_item &&
  //   (!!person.associated_item.photo_url ||
  //     !!person.associated_item.screenshot ||
  //     !!person.associated_item.timelapse);

  const posts = useMemo(
    () =>
      person?.associated_item
        ? [person.associated_item, ...person.posts]
        : person?.posts || [],
    [person?.posts, person?.associated_item]
  );

  useEffect(() => {
    if (posts.length === 0) return;
    fitBounds(
      posts.map((post) => post.location),
      { setZoom: false }
    );
  }, [posts, fitBounds]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <l-waveform size={48} color="white" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <p className="w-full max-w-lg text-red-500">{error.message}</p>
      </div>
    );
  }

  const onPostOpen = (value: string | SingleRecord) => {
    if (!map || !value) return;
    let post: SingleRecord | undefined;
    if (typeof value === "string") {
      const [type, id] = value.split("-");
      post = posts.find((obj) => obj.type === type && obj.id === parseInt(id));
    } else {
      post = value;
    }
    if (!post) return;
    if (post.type === "sports_tracker") {
      const { type, center_position, start_position, stop_position } = post;
      if (!center_position || !start_position || !stop_position) return;
      renderDirections({
        type,
        center_position,
        start_position,
        stop_position,
      }).then((directions) => setDirections(directions));
    } else {
      map.panTo(post.location);
      map.setZoom(22);
    }
  };

  return (
    <section className="h-full flex flex-col gap-2 px-4 md:px-8 pt-4 pb-8 w-full">
      <div
        className={cn("sticky bg-[var(--body-color)] h-max z-40 py-4 -top-11")}
      >
        <div className="flex items-end gap-4 justify-between">
          <div className="flex flex-col gap-4 text-[#98A2B3] dark:text-[#CECFD2] stroke-[#98A2B3]/60 dark:stroke-[#CECFD2]/60">
            <div className="flex items-center gap-3 text-base">
              <Link
                className="py-1 px-2 rounded hover:bg-slate-300 dark:hover:bg-[#1F242F]"
                to="/people-investigated"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.66667 13.1663H12.3333M8.18141 1.30297L2.52949 5.6989C2.15168 5.99276 1.96278 6.13968 1.82669 6.32368C1.70614 6.48667 1.61633 6.67029 1.56169 6.86551C1.5 7.0859 1.5 7.32521 1.5 7.80384V13.833C1.5 14.7664 1.5 15.2331 1.68166 15.5896C1.84144 15.9032 2.09641 16.1582 2.41002 16.318C2.76654 16.4996 3.23325 16.4996 4.16667 16.4996H13.8333C14.7668 16.4996 15.2335 16.4996 15.59 16.318C15.9036 16.1582 16.1586 15.9032 16.3183 15.5896C16.5 15.2331 16.5 14.7664 16.5 13.833V7.80384C16.5 7.32521 16.5 7.0859 16.4383 6.86551C16.3837 6.67029 16.2939 6.48667 16.1733 6.32368C16.0372 6.13968 15.8483 5.99276 15.4705 5.69891L9.81859 1.30297C9.52582 1.07526 9.37943 0.9614 9.21779 0.917634C9.07516 0.879018 8.92484 0.879018 8.78221 0.917634C8.62057 0.9614 8.47418 1.07526 8.18141 1.30297Z"
                    stroke="currentColor"
                    strokeWidth="1.66667"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              <ArrowRightIcon />
              <Link
                className="text-font py-1 px-2 rounded bg-slate-300 dark:bg-[#1F242F] max-w-xs line-clamp-1"
                to={`/people-investigated/${person?.id}`}
              >
                {person?.realname || person?.username || "User"}
              </Link>
            </div>
            <h1 className="text-[var(--text-color)] font-medium text-3xl sm:text-4xl line-clamp-2 max-w-7xl">
              {person?.realname || person?.username || "User"}
            </h1>
          </div>
        </div>
      </div>
      {person && (
        <div className="grid grid-cols-2 gap-6">
          <Card>
            <CardTitle>Author Info</CardTitle>
            <CardContent>
              <AuthorRef person={person} hideInvestigate />
            </CardContent>
          </Card>
          <Card className="row-span-2">
            <CardHeader className="p-0 flex-row items-center relative justify-between">
              <CardTitle>Activity</CardTitle>
              {(person.posts.length > 0 || !!person.associated_item) && (
                <Button
                  disabled={isSearching}
                  className="absolute right-0"
                  size="sm"
                  onClick={handleSearch}
                  variant="outline"
                >
                  {isSearching ? (
                    <l-waveform
                      size={16}
                      color="var(--text-color)"
                      stroke={1}
                    />
                  ) : (
                    <SearchIcon size={16} />
                  )}
                  Search posts
                </Button>
              )}
            </CardHeader>
            <CardContent className="flex-1 relative flex flex-col">
              {person.posts.length > 0 || !!person.associated_item ? (
                <PersonActivity onPostOpen={onPostOpen} />
              ) : (
                <div className="flex flex-col items-center gap-4 flex-1 justify-center border-slate-300 dark:border-slate-800 border rounded-md min-h-48">
                  {isSearching || isValidating ? (
                    <l-waveform
                      size={48}
                      color="var(--text-color)"
                    ></l-waveform>
                  ) : (
                    <>
                      <Button onClick={handleSearch} variant="outline">
                        <SearchIcon size={16} />
                        Search posts
                      </Button>
                      <p className="text-base text-font opacity-80">
                        Find posts of the user
                      </p>
                    </>
                  )}
                </div>
              )}
            </CardContent>
          </Card>
          <Card ref={mapContainer}>
            <CardTitle>Map</CardTitle>
            <CardContent className="flex-1 min-h-96">
              <Map
                {...(person?.associated_item
                  ? { center: person.associated_item.location, zoom: 15 }
                  : {})}
                // changeMapBounds={setBounds}
                defaultOptions={{
                  mapTypeId: window.google.maps.MapTypeId.SATELLITE,
                }}
              >
                {/* {isPolygon && item.polygon ? (
                  <PolygonF
                    paths={JSON.parse(item.polygon)}
                    options={{
                      fillColor: polygonModule.color,
                      fillOpacity: 0.7,
                      strokeColor: polygonModule.color,
                    }}
                    key={item.id}
                  />
                ) : routeVisible ? (
                  <DirectionsRenderer directions={directions} />
                ) : (
                  location && (
                    <CustomMarker {...item} type={type} position={location} />
                  )
                )} */}
                {person.associated_item && (
                  <CustomMarker
                    {...person.associated_item}
                    type={person.type}
                    position={person.associated_item.location}
                  />
                )}
                <MarkerClustererF
                  options={{
                    styles: [
                      {
                        backgroundPosition: "3% -14%",
                        height: 74,
                        textColor: "#FFFFFF",
                        width: 74,
                        url: clusterIcons[
                          person.type as keyof typeof clusterIcons
                        ],
                      },
                    ],
                  }}
                >
                  {(clusterer) => (
                    <>
                      {person.posts.map((obj, index) => (
                        <CustomMarker
                          {...obj}
                          position={obj.location}
                          clusterer={clusterer}
                          type={person.type}
                          onMouseOver={() =>
                            obj.type !== "sports_tracker" &&
                            setInfoWindow(index)
                          }
                          onClick={() => {
                            if (obj.type !== "sports_tracker") return;
                            setInfoWindow(null);
                            const {
                              type,
                              center_position,
                              start_position,
                              stop_position,
                            } = obj;
                            if (
                              !center_position ||
                              !start_position ||
                              !stop_position
                            )
                              return;
                            renderDirections({
                              type,
                              center_position,
                              start_position,
                              stop_position,
                            }).then((directions) => setDirections(directions));
                          }}
                          key={index}
                        >
                          {infoWindow === index && (
                            <InfoWindowF
                              position={obj.location}
                              onCloseClick={() => setInfoWindow(null)}
                            >
                              <InfoWindowContent
                                obj={{ ...obj, type: person.type }}
                                //                               url={obj.url}
                              />
                            </InfoWindowF>
                          )}
                        </CustomMarker>
                      ))}
                    </>
                  )}
                </MarkerClustererF>
                {directions && <DirectionsRenderer directions={directions} />}
              </Map>
            </CardContent>
          </Card>
          <div className="col-span-2 flex flex-col gap-4">
            <div className="flex w-full items-center justify-between gap-4">
              <div className="flex items-center gap-4">
                <h2 className="text-font font-medium text-2xl">Posts</h2>
              </div>
              <Pagination
                currentPage={page}
                totalPage={Math.ceil(person.posts.length / 50)}
                onPageChange={(value) => setPage(value)}
              />
            </div>
            {person.posts.length > 0 || person.associated_item ? (
              <div className="flex flex-col w-full md:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))] gap-8 items-start">
                {person.associated_item && page === 1 && (
                  <ObjectRef
                    {...person.associated_item}
                    menuChildren={
                      <Button
                        className="-ml-1.5"
                        onClick={() => {
                          mapContainer.current?.scrollIntoView({
                            block: "center",
                            behavior: "smooth",
                          });
                          onPostOpen(person.associated_item);
                        }}
                        key={
                          person.associated_item.type +
                          person.associated_item.id +
                          "zoom"
                        }
                      >
                        Zoom on map
                      </Button>
                    }
                  />
                )}
                {person.posts
                  .slice(50 * (page - 1), 50 * (page - 1) + 50)
                  .map((post) => (
                    <ObjectRef
                      {...post}
                      key={post.type + post.id}
                      menuChildren={
                        <Button
                          className="-ml-1.5"
                          onClick={() => {
                            mapContainer.current?.scrollIntoView({
                              block: "center",
                              behavior: "smooth",
                            });
                            onPostOpen(post);
                          }}
                          key={post.type + post.id + "zoom"}
                        >
                          Zoom on map
                        </Button>
                      }
                    />
                  ))}
              </div>
            ) : isSearching || isValidating ? (
              <div className="max-w-max mx-auto w-full">
                <l-waveform size={48} color="var(--text-color)" />
              </div>
            ) : (
              <p className="text-font text-base text-center">No posts found</p>
            )}
          </div>
        </div>
      )}
      <LoadingBar height={8} color="#ff00cd" progress={progress} />
    </section>
  );
}
