import { Button } from "@/components/ui/button";
import { Plus, SearchIcon } from "lucide-react";
import usePeopleInvestigated from "@/lib/single-item/people-investigated";
import PersonRef from "@/components/people-investigated/person";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { Input } from "@/components/ui/input";
import axios from "axios";
import { getTaskInfo } from "@/utils/single-item/lib";
import toast from "react-hot-toast";
import Toast from "@/components/toast";
import ModuleSelect from "@/components/people-investigated/module-select";
import { useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";

const modulePlaceholders: Record<string, string> = {
  flickr: "45960275@N05",
  youtube: "GuardianNews",
  instagram: "username",
  twitter: "pdxpolicelog",
  sports_tracker: "username",
  // Add more modules and placeholders as needed
};

export default function PeopleInvestigated() {
  const [moduleName, setModuleName] = useState<string>("flickr");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { data: people, isLoading, error, mutate } = usePeopleInvestigated();
  const [isSearching, setIsSearching] = useState(false);
  const [input, setInput] = useState("");
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);

  async function handleSearch() {
    setIsSearching(true);
    setProgress(5);
    try {
      const { data } = await axios.post("/addUsername/", {
        username: input,
        modules: [moduleName],
      });
      if (data.error) {
        toast.custom(
          (t) => <Toast {...t} isError title="Error" subtitle={data.message} />,
          { duration: Infinity }
        );
        setIsSearching(false);
        setProgress(100);
        return;
      }
          mutate();
          setInput("");
          setIsDialogOpen(false);
          setIsSearching(false);
          setProgress(100);
          data.person?.id &&
            navigate(`/people-investigated/${data.person.id}`);
        }

        catch (error) {
      console.error("There was an error!", error);
    }
  }

  if (isLoading) {
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <l-waveform size={48} color="var(--text-color)" />
      </div>
    );
  }

  if (error)
    return (
      <div className="w-full h-screen flex items-center justify-center text-font">
        Error: {error.message}
      </div>
    );

  return (
    <section className="h-full flex flex-col gap-4 w-full p-8">
      {people && people.length > 0 ? (
        <>
          <div className="flex items-center gap-4 justify-between">
            <h1 className="text-[var(--text-color)] text-3xl font-medium">
              {people?.length || 0} People Investigated
            </h1>
            <Dialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
              <DialogTrigger asChild>
                <Button variant="outline">
                  <Plus size={16} />
                  Add new
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>Search for users by username</DialogHeader>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSearch();
                  }}
                >
                  <div className="py-2 mb-4 space-y-4">
                    <ModuleSelect
                      className="!bg-body dark:!bg-[#101921]"
                      value={moduleName}
                      onChange={(value) => setModuleName(value)}
                    />
                    <Input
                      type="search"
                      id="add_username"
                      value={input}
                      className="!bg-body dark:!bg-[#101921]"
                      onChange={(event) => setInput(event.target.value)}
                      placeholder={
                        modulePlaceholders[moduleName ?? "default"] ||
                        "Enter username"
                      } // Safe fallback to default
                    />
                  </div>
                  <DialogFooter>
                    <Button
                      disabled={isSearching}
                      size="sm"
                      className="dark:bg-blue"
                    >
                      {isSearching ? (
                        <l-waveform
                          color="var(--text-color)"
                          size={16}
                          stroke={1.5}
                        />
                      ) : (
                        <SearchIcon size={16} />
                      )}
                      Search
                    </Button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col w-full md:grid grid-cols-[repeat(auto-fill,minmax(340px,1fr))] gap-8 items-start">
            {people.map((person) => (
              <PersonRef {...person} key={`${person.type}-${person.id}`} />
            ))}
          </div>
        </>
      ) : (
        <div className="h-full w-full flex flex-col justify-center items-center">
          <form
            className="flex flex-col gap-2 w-full max-w-xl"
            onSubmit={(e) => {
              e.preventDefault();
              handleSearch();
            }}
          >
            <h3 className="text-[var(--text-color)] mb-2">
              Search for users by username
            </h3>
            <ModuleSelect
              className="h-11"
              value={moduleName}
              onChange={(value) => setModuleName(value)}
            />
            <Input
              type="search"
              id="add_username"
              value={input}
              onChange={(event) => setInput(event.target.value)}
              className="h-11"
              placeholder={
                modulePlaceholders[moduleName ?? "default"] || "Enter username"
              } // Safe fallback to default
            />
            <div className="flex justify-end mt-2">
              <Button
                disabled={isSearching}
                // className="bg-[var(--primary-color)] rounded max-w-max self-end py-2.5 px-5 text-white text-base disabled:opacity-80 flex items-center gap-2"
              >
                {isSearching ? (
                  <l-waveform size={16} stroke={1.5} />
                ) : (
                  <SearchIcon size={16} />
                )}
                Search
              </Button>
            </div>
          </form>
        </div>
      )}
      <LoadingBar height={8} color="#ff00cd" progress={progress} />
    </section>
  );
}
