export default function NoflyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="80%"
        width="80%"
    viewBox="0 0 384 512"
      strokeWidth="3"
    fill="inherit"
    >
      <g>
	<path  d="M503.5,144.028H439v-0.5c0-12.958-10.542-23.5-23.5-23.5s-23.5,10.542-23.5,23.5v0.5h-64.5c-4.142,0-7.5,3.358-7.5,7.5
		s3.358,7.5,7.5,7.5H384v8.5c0,0.168,0.01,0.333,0.013,0.5h-48.357c-0.007,0-0.014,0-0.021,0H175.376c-0.012,0-0.024,0-0.036,0
		h-48.353c0.003-0.167,0.013-0.332,0.013-0.5v-8.5h56.5c4.142,0,7.5-3.358,7.5-7.5s-3.358-7.5-7.5-7.5H119v-0.5
		c0-12.958-10.542-23.5-23.5-23.5S72,130.57,72,143.528v0.5H7.5c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5H64v8.5
		c0,17.369,14.131,31.5,31.5,31.5c11.737,0,21.987-6.457,27.406-16h47.188l6.997,20.991c5.385,16.155,20.444,27.009,37.473,27.009
		H248v18.234c-9.29,3.138-16,11.93-16,22.266c0,12.958,10.542,23.5,23.5,23.5s23.5-10.542,23.5-23.5c0-10.336-6.71-19.128-16-22.266
		v-18.234h33.436c17.029,0,32.088-10.854,37.473-27.009l6.997-20.991h47.188c5.419,9.543,15.669,16,27.406,16
		c17.369,0,31.5-14.131,31.5-31.5v-8.5h56.5c4.142,0,7.5-3.358,7.5-7.5S507.642,144.028,503.5,144.028z M87,143.528
		c0-4.687,3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5v0.5H87V143.528z M95.5,184.028c-9.098,0-16.5-7.402-16.5-16.5v-8.5h33v8.5
		C112,176.626,104.598,184.028,95.5,184.028z M255.5,280.028c-4.687,0-8.5-3.813-8.5-8.5s3.813-8.5,8.5-8.5s8.5,3.813,8.5,8.5
		S260.187,280.028,255.5,280.028z M319.678,199.276c-3.34,10.02-12.68,16.752-23.243,16.752h-81.871
		c-10.563,0-19.903-6.732-23.243-16.752l-5.416-16.248h139.188L319.678,199.276z M407,143.528c0-4.687,3.813-8.5,8.5-8.5
		s8.5,3.813,8.5,8.5v0.5h-17V143.528z M432,167.528c0,9.098-7.402,16.5-16.5,16.5s-16.5-7.402-16.5-16.5v-8.5h33V167.528z"/>
	<path d="M294.744,310.772c2.929-2.929,2.929-7.677,0-10.606c-2.929-2.929-7.678-2.929-10.606,0
		c-15.791,15.791-41.484,15.791-57.275,0c-2.929-2.929-7.678-2.929-10.606,0c-2.929,2.929-2.929,7.677,0,10.606
		c10.82,10.82,25.032,16.229,39.244,16.229S283.924,321.592,294.744,310.772z"/>
	<path d="M193.628,322.793c-2.929,2.929-2.929,7.677,0,10.606c16.526,16.527,38.5,25.628,61.872,25.628s45.345-9.102,61.872-25.628
		c2.929-2.929,2.929-7.678,0-10.606c-2.929-2.929-7.678-2.929-10.606,0c-13.693,13.693-31.9,21.235-51.265,21.235
		s-37.572-7.541-51.265-21.235C201.307,319.865,196.558,319.864,193.628,322.793z"/>
	<path d="M329.393,345.421c-40.744,40.744-107.041,40.744-147.785,0c-2.929-2.929-7.678-2.929-10.606,0
		c-2.929,2.929-2.929,7.677,0,10.606c23.296,23.296,53.898,34.945,84.499,34.945s61.203-11.648,84.499-34.945
		c2.929-2.929,2.929-7.677,0-10.606C337.071,342.492,332.322,342.492,329.393,345.421z"/>
</g>
    </svg>
  );
}
