import { useState } from "react";
import { GoogleMap, Marker, TrafficLayerF } from "@react-google-maps/api";
import Dark from "../map_with_search/dark.json";
import Bright from "../map_with_search/bright.json";
import ossIcon from "../../const/search/imgs/oss-pink.png";
import { useTutorial } from "../../providers/tutorial";
import useMap from "../../hooks/useMap";
import { useTheme } from "@/providers/theme";
import BrightHidden from "../map_with_search/bright-hidden.json";
import DarkHidden from "../map_with_search/dark-hidden.json";
import { useEffect } from "react";

const defaultCenter = { lat: 38.5, lng: -98.0 };

const Map = (props) => {
  const { changeMapBounds, children, defaultOptions, areObjectsHidden } = props;
  const [mapTypeId, setMapTypeId] = useState(
    defaultOptions?.mapTypeId || window.google.maps.MapTypeId.ROADMAP
  );
  const isSatellite = mapTypeId === "hybrid" || mapTypeId === "satellite";
  const { step, changeStep, isFinished } = useTutorial();
  const { map, onLoad } = useMap();
  const { isDark } = useTheme();
const [trafficLayer, setTrafficLayer] = useState(null);
  const handleMapBoundsChanged = () => {
    if (!map || !changeMapBounds) return;
    changeMapBounds(map.getBounds());
  };

     useEffect(() => {
    if (map && window.google) {
      if (props.isTraffic) {
        const newTrafficLayer = new window.google.maps.TrafficLayer();
        newTrafficLayer.setMap(map);
        setTrafficLayer(newTrafficLayer);
      } else if (trafficLayer) {
        trafficLayer.setMap(null);
        setTrafficLayer(null);
      }
    }
  }, [props.isTraffic, map]);
  const options = {
    disableDefaultUI: true,
    streetViewControl: true,
    mapTypeControl: true,
    scaleControl: true,
    zoomControl: true,
    minZoom: 1,
    maxZoom: 30,
    buildings: true,
    rotateControl: true,
    labels: true,
    fullscreenControl: true,
    backgroundColor: "var(--body-color)",
    ...(defaultOptions || {}),
    mapTypeId,
    styles: isSatellite
      ? areObjectsHidden
        ? Bright
        : BrightHidden
      : isDark
      ? areObjectsHidden
        ? Dark
        : DarkHidden
      : areObjectsHidden
      ? Bright
      : BrightHidden,
  };

  const changeCoords = (coords) => {
    const tutorialMapVisible = !isFinished && step === 4;
    if (tutorialMapVisible) changeStep();
    if (!props.setPlace) return;
    props.setPlace(coords);
  };

  return (
    <GoogleMap
      zoom={props.zoom || 1}
      mapContainerStyle={{
        height: "100%",
        width: "100%",
        backgroundColor: "var(--body-color)",
      }}
      center={props.center || defaultCenter}
      options={options}
      onLoad={
        props.onLoad
          ? (map) => {
              props.onLoad(map);
              onLoad(map);
            }
          : onLoad
      }
      onClick={(e) =>
        changeCoords({ lat: e.latLng.lat(), lng: e.latLng.lng() })
      }
      onBoundsChanged={handleMapBoundsChanged}
      onMapTypeIdChanged={() => map && setMapTypeId(map.getMapTypeId())}
    >
      {children}
      {props.place && (
        <Marker
          position={props.place}
          icon={{
            url: ossIcon,
            scaledSize: new window.google.maps.Size(60, 60), // Set the size of your icon
          }}
        />
      )}
    {props.isTraffic ? <TrafficLayerF /> : null}
    </GoogleMap>
  );
};

export default Map;
