import { useRef, useState } from "react";
import Toast from "../../components/toast";
import toast from "react-hot-toast";
import { getFaceSearch, getTaskInfo } from "../../utils/single-item/lib";
import { useParams } from "react-router-dom";

export default function useFaceSearch(onSuccess?: () => void) {
  const { workspace_id, type, item_id, hashtag_id } = useParams();
  const timer = useRef<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState<FaceSearchPhoto[]>([]);
  const [progress, setProgress] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState(true);

  async function handleSearch() {
    const paramsAvailable =
      !!hashtag_id || (!!workspace_id && !!type && !!item_id);
    if (isDisabled || !paramsAvailable) return;
    setIsLoading(true);
    const loading = toast.custom(
      (t) => (
        <Toast
          isLoading
          {...t}
          title="Face searching the photo"
          subtitle="It might take couple minutes, please be patient"
        />
      ),
      { duration: Infinity }
    );
    try {
      const { results, error } = await getFaceSearch(
        hashtag_id
          ? { hashtag_id, workspace_type: "hashtag", "type": type, "item_id": item_id }
          : {
              workspace_id,
              type,
              item_id,
            }
      );
      if (error) {
        setIsLoading(false);
        toast.dismiss(loading);
        toast.custom(
          (t) => <Toast {...t} isError title="Error" subtitle={error} />,
          { duration: Infinity }
        );
        return;
      }
      setProgress(10);
      const [{ task_id }] = results;
      timer.current = window.setInterval(async () => {
        const { results } = await getTaskInfo<FaceSearchPhoto[]>(task_id);
        if (!results[0]) {
          setProgress(null);
          clearInterval(timer.current!);
          toast.dismiss(loading);
          setIsLoading(false);
          toast.custom(
            (t) => (
              <Toast
                {...t}
                isError
                title="Error"
                subtitle="Internal server error"
              />
            ),
            { duration: Infinity }
          );
          return;
        }
        const { result, state } = results[0];
        switch (state) {
          case "SUCCESS":
            if (result.error) {
              toast.custom(
                (t) => (
                  <Toast {...t} isError title="Error" subtitle={result.error} />
                ),
                { duration: Infinity }
              );
            }
            if (result.results && result.results.length > 0) {
              setPhotos(result.results);
              setIsDisabled(true);
              onSuccess && onSuccess();
            } else {
              toast.custom((t) => (
                <Toast {...t} isError title={"No results have been found!"} />
              ));
            }
            break;
          case "PROGRESS":
            setProgress(result.percent);
            break;
          default:
            break;
        }
        if (state !== "PROGRESS" && state !== "PENDING") {
          clearInterval(timer.current!);
          toast.dismiss(loading);
          setIsLoading(false);
          setProgress(100);
        }
      }, 1000);
    } catch (err) {
      console.log(err);
    }
  }

  return {
    progress,
    isDisabled,
    setIsDisabled,
    isLoading,
    photos,
    setPhotos,
    handleSearch,
  };
}
