import { SEARCH_OPTIONS } from "@/const/search";
import { Label } from "@/components/ui/label";
import {
  MultiSelect,
  MultiSelectContent,
  MultiSelectInput,
  MultiSelectItem,
  MultiSelectList,
  MultiSelectTrigger,
} from "@/components/ui/multi-select";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@/components/ui/scroll-area";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faClock,
  faComment,
  faFlagUsa,
  faLocationDot,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import DateInput from "../inputs/date";
import KeywordInput from "../inputs/keyword";
import useFilters from "@/providers/search/filters";
import { useTutorial } from "@/providers/tutorial";
import toast from "react-hot-toast";
import Toast from "@/components/toast";
import { cn } from "@/utils/cn";
import ShowAll from "./show-all";
import { Search } from "lucide-react";
import StepPopup from "@/components/tutorial/step";
import Popup from "@/components/Popup";
import { useParams } from "react-router-dom";
import React, { useState, useCallback } from "react";

const padWithZero = (number: number) => (number < 10 ? `0${number}` : number);

const formattedDate = (date: Date) =>
  `${date.getFullYear()}-${padWithZero(date.getMonth() + 1)}-${padWithZero(
    date.getDate()
  )}`;

export default function Regular({
  place,
  selected,
  onSelectionChange,
  mapBounds,
  zoom,
}: Omit<MenuProps, "isMenuOpen" | "toggleMenu">) {
  const { workspace_id } = useParams();
  const { startDate, endDate, keyword, processSearch, loadingRequests } =
    useFilters();
  const { changeStep, step, isFinished } = useTutorial();

  const tutorialSelectVisible = !isFinished && step === 5;
  const tutorialSearchVisible = !isFinished && step === 6;
  const tutorialCompleted = !isFinished && step === 7;
  const zoom_level = zoom.current;

  async function handleSearch() {
    !isFinished && step === 6 && changeStep();
    const valuesArray = Object.values(selected).reduce((acc, currentArray) => {
      return acc.concat(currentArray.map((item) => item.value));
    }, [] as string[]);
    if (valuesArray.length === 0) {
      return toast.custom((t) => (
        <Toast
          {...t}
          isError
          title="Error"
          subtitle="You need to select at least one module"
        />
      ));
    }
    const request_data = {
      zoom: zoom_level,
      options: valuesArray,
      keywords: keyword,
      ...(startDate
        ? {
            date_from: formattedDate(startDate),
          }
        : {}),
      ...(endDate
        ? {
            date_to: formattedDate(endDate),
          }
        : {}),
      coordinates_id: Number(workspace_id),
      ...place,
      ...mapBounds,
    };
    await processSearch(request_data);
  }

  return (
    <>
      {tutorialCompleted && (
        <Popup
          title="Congratulations!"
          paragraph="You've completed a quick tutorial showacasing basic functionality of the app. Explore more options and enjoy your possibilities!"
          submitText="Got it!"
          onClose={changeStep}
          onSubmit={undefined}
        />
      )}
      {(tutorialSearchVisible || tutorialSelectVisible) && (
        <div className="fixed bg-black/40 backdrop-blur-[2px] inset-0 z-10" />
      )}
      <ScrollArea
        className={cn(
          "flex-1 max-h-[calc(100vh-215px)]",
          tutorialSelectVisible && "overflow-visible [&>div]:!overflow-visible"
        )}
      >
        <div className="flex flex-col gap-4 px-6">
          {SEARCH_OPTIONS.map((opt) => (
            <MultiSelectRef
              {...opt}
              selected={selected}
              onSelectionChange={onSelectionChange}
              tutorialSelectVisible={tutorialSelectVisible}
              key={opt.title}
            />
          ))}
          <DateInput type="start" />
          <DateInput type="end" />
          <KeywordInput />
        </div>
      </ScrollArea>
      <div className="grid grid-cols-2 gap-4 px-6">
        <ShowAll />
        <div className={cn("relative", tutorialSearchVisible && "z-20")}>
          <Button
            onClick={handleSearch}
            disabled={loadingRequests.search}
            className="h-9 font-medium"
          >
            <Search size={16} />
            Search
          </Button>
          {tutorialSearchVisible && (
            <StepPopup
              title="Search for objects"
              paragraph="Finally, after you've chosen your location and category of objects, you can search for them."
              actionText="Click on the button"
              position="left-[120%] bottom-[120%]"
              iconPosition="-left-5 top-full rotate-45"
            />
          )}
        </div>
      </div>
    </>
  );
}

type MultiSelectProps = {
  title: string;
  options: any[];
  tutorialSelectVisible: boolean;
  selected: Record<string, ValueLabel[]>;
  onSelectionChange: (type: string, values: ValueLabel[]) => void;
};

const MultiSelectRef = ({
  title,
  options,
  tutorialSelectVisible,
  selected,
  onSelectionChange,
}: MultiSelectProps) => {
  const { changeStep } = useTutorial();
  const tutorialVisible = tutorialSelectVisible && title === "Social Media";
  const validOptions: any[] = options.filter((item) => item.search !== false);
  return (
    <div
      className={cn("flex flex-col gap-2 relative", tutorialVisible && "z-20")}
    >
      <Label className="text-sm font-normal opacity-80" htmlFor={title}>
        {title}
      </Label>
      <MultiSelect
        className="max-w-[232px]"
        values={selected[title] || []}
        onSelectionChange={(values) => {
          onSelectionChange(title, values);
          tutorialSelectVisible && changeStep();
        }}
      >
        <MultiSelectTrigger>
          <MultiSelectInput placeholder={`Select ${title.toLowerCase()}`} />
        </MultiSelectTrigger>
        <MultiSelectContent>
          <MultiSelectList>
            <MultiSelectItem
              value={{ value: "all", label: "All" } as any}
              onSelect={() =>
                onSelectionChange(
                  title,
                  selected[title] &&
                    selected[title].length === validOptions.length
                    ? []
                    : validOptions.map(({ value, label }) => ({
                        value,
                        label,
                      }))
                )
              }
              className={cn(
                "rounded-md cursor-pointer px-2 py-1.5 transition-colors flex gap-2 text-base"
              )}
            >
              {selected[title] && selected[title].length === validOptions.length
                ? "Remove"
                : "Select"}{" "}
              all
            </MultiSelectItem>
            {validOptions.map((opt: any) => {
              const {
                value,
                icon,
                label,
                onlyUsa,
                workRadius,
                workBbox,
                workKeyword,
                workTimestamp,
                workLonger,
              } = opt;
              const Icon = icon;
              return (
                <MultiSelectItem value={opt} key={value}>
                  {icon &&
                    (typeof icon === "function" ? (
                      <div className="fill-[var(--text-color)] stroke-[var(--text-color)] w-4">
                        <Icon />
                      </div>
                    ) : (
                      <FontAwesomeIcon className="w-4" size="lg" icon={icon} />
                    ))}
                  {label}
                  {onlyUsa && (
                    <FontAwesomeIcon icon={faFlagUsa}></FontAwesomeIcon>
                  )}
                  {workRadius && (
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      className="text-[var(--text-color)] opacity-80"
                    />
                  )}
                  {workBbox && (
                    <FontAwesomeIcon
                      className="text-[var(--text-color)] opacity-80"
                      icon={faUsersViewfinder}
                    />
                  )}
                  {workTimestamp && (
                    <FontAwesomeIcon
                      icon={faCalendarDays}
                      className="text-[var(--text-color)] opacity-80"
                    />
                  )}
                  {workKeyword && (
                    <FontAwesomeIcon
                      icon={faComment}
                      className="text-[var(--text-color)] opacity-80"
                    />
                  )}
                  {workLonger && (
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                  )}
                </MultiSelectItem>
              );
            })}
          </MultiSelectList>
        </MultiSelectContent>
      </MultiSelect>
      {tutorialVisible && (
        <StepPopup
          title="Choose an object type"
          paragraph="For this example use the social media category. Expand the dropdown and choose from the available options."
          actionText="Click on an option"
          position="left-[110%] top-[120%]"
        />
      )}
    </div>
  );
};
